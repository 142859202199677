import { createAction, createReducer } from "@reduxjs/toolkit";

/**
 * @template T
 * @typedef {import('types/stateTypes').PayloadPreparator<T>} PayloadPreparator
 */

/** @type {boolean} */
const initialState = false;

export const setIsDataChanged = createAction(
  "isDataChanged/set",
  /**
   * @type {PayloadPreparator<boolean>}
   */
  (value) => ({ payload: value })
);

export const resetIsDataChanged = createAction("isDataChanged/unset");

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(setIsDataChanged, (state, action) => action.payload);
  builder.addCase(resetIsDataChanged, () => false);
});

export default reducer;
