import * as DFns from "date-fns";
import {
  DEFAULT_TIMEZONE_OFFSET_FOR_FIRST_BUILDING,
  lockTypes,
  steps,
} from "models/constants";

export const handleFullNameSort = (a, b) =>
  `${a.firstName}${a.lastName}` > `${b.firstName}${b.lastName}` ? 1 : -1;

export const handleNameSort = (a, b) => (a.name > b.name ? 1 : -1);

export const removeTimezoneOffset = (currentDate) => {
  try {
    const parsedStartDate = new Date(currentDate);
    const utcOffset = -parsedStartDate.getTimezoneOffset();
    const endDate = DFns.addMinutes(parsedStartDate, utcOffset);

    return endDate?.toISOString().slice(0, 19).replace("T", " ");
  } catch (e) {
    return "";
  }
};

export const addTimezoneOffset = (currentDate) => {
  try {
    const parsedStartDate = new Date(currentDate);
    const utcOffset = parsedStartDate.getTimezoneOffset();
    const endDate = DFns.addMinutes(parsedStartDate, utcOffset);

    return endDate?.toISOString().slice(0, 19).replace("T", " ");
  } catch (e) {
    return "";
  }
};

export const addMonths = (startDate, months) => {
  try {
    const parsedStartDate = new Date(startDate.slice(0, 10) + " 00:00:00");
    const endDate = DFns.addMonths(parsedStartDate, months);

    return removeTimezoneOffset(endDate?.toISOString());
  } catch (e) {}
};

export const addDays = (startDate, days) => {
  try {
    const parsedStartDate = new Date(startDate);
    const endDate = DFns.addDays(parsedStartDate, days);

    return removeTimezoneOffset(endDate?.toISOString());
  } catch (e) {}
};

export const addHours = (startDate, hours) => {
  try {
    const parsedStartDate = new Date(startDate);
    const endDate = DFns.addHours(parsedStartDate, Number(hours));

    return removeTimezoneOffset(endDate?.toISOString());
  } catch (e) {}
};

export const isDaySavingTime = (date) => {
  const jan = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
  const jul = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
  return Math.max(jan, jul) !== date.getTimezoneOffset();
};

export const optionsMapper = ({ id, name }) => ({ value: id, label: name });

export const getIsoTodayByTimeZone = (
  timezoneOffset = DEFAULT_TIMEZONE_OFFSET_FOR_FIRST_BUILDING
) => {
  const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);
  const today = new Date();
  const now = DFns.addHours(
    today,
    timezoneOffset - (isDaySavingTime(today) ? 0 : 1)
  );

  const year = now.getUTCFullYear();
  const month = now.getUTCMonth() + 1;
  const day = now.getUTCDate();
  const hours = now.getUTCHours();
  const minutes = now.getUTCMinutes();
  const seconds = now.getUTCSeconds();

  const result = `${year}-${addLeadingZero(month)}-${addLeadingZero(
    day
  )} ${addLeadingZero(hours)}:${addLeadingZero(minutes)}:${addLeadingZero(
    seconds
  )}`;

  return result;
};

export const getMonthInInterval = (startDate, stopDate) => {
  try {
    const start = new Date(startDate.substring(0, 10));
    const end = new Date(stopDate.substring(0, 10));

    const yearDifference = end.getFullYear() - start.getFullYear();
    const monthDifference = end.getMonth() - start.getMonth();
    const duration = monthDifference + yearDifference * 12;

    return duration > 0 ? duration : 0;
  } catch (e) {
    return 0;
  }
};

export const getDaysInInterval = (startDate, stopDate) => {
  try {
    const roundedStartDate = `${startDate.substring(0, 10)} 00:00:00`;
    const start = new Date(roundedStartDate);
    const end = new Date(stopDate);

    const difference = end.getTime() - start.getTime();
    const duration = Math.floor(difference / (24 * 1000 * 60 * 60));

    return duration > 0 ? duration : 0;
  } catch (e) {
    return 0;
  }
};

export const getNightsInInterval = (startDate, stopDate) => {
  try {
    const roundedStartDate = `${startDate.substring(0, 10)} 12:00:00`;
    const start = new Date(roundedStartDate);
    const end = new Date(stopDate);

    const difference = end.getTime() - start.getTime();
    const duration = Math.floor(difference / (24 * 1000 * 60 * 60));
    return duration > 0 ? duration : 0;
  } catch (e) {
    return 0;
  }
};

export const getHoursInInterval = (startDate, stopDate) => {
  try {
    const start = new Date(startDate);
    const end = new Date(stopDate);

    const difference = end.getTime() - start.getTime();
    const duration = Math.ceil(difference / (1000 * 60 * 60));
    return duration > 0 ? duration : 0;
  } catch (e) {
    return 0;
  }
};

export const capitalizeFirstLetter = (text) =>
  `${text.charAt(0).toUpperCase()}${text.slice(1).toLowerCase()}`;

export const normalizePhoneNumber = (phoneNumber) => {
  const asArray = phoneNumber.replace(/[^0-9]/g, "").split("");
  const cleanNunber = asArray
    .reverse()
    .join("")
    .substring(0, 10)
    .split("")
    .reverse()
    .join("");

  return `${cleanNunber.substring(0, 3)}-${cleanNunber.substring(
    3,
    6
  )}-${cleanNunber.substring(6)}`;
};

export const getLockDescription = ({
  deviceId,
  lockActivationCode,
  lockType,
  serialNumber,
  provisioned,
}) => {
  switch (lockType) {
    case lockTypes.MasterLock:
      return `Device ID ${deviceId}, Activation code - ${lockActivationCode}`;
    case lockTypes.Yale:
      if (!provisioned) {
        return (
          <>
            Serial Number {serialNumber},{" "}
            <span style={{ color: "red", fontWeight: "bold" }}>
              Unprovisioned Yale lock!
            </span>
          </>
        );
      }
      return `Serial number ${serialNumber}, Lock type "${String(lockType)}"`;
    default:
      return `Device ID ${deviceId}, Lock type "${String(lockType)}"`;
  }
};

/** @param {import("types/commonTypes").BuildingData} currentBuilding */
export const getYaleFlowStartScreen = ({
  yaleAccountConfirmed,
  yaleAccountExists,
  yaleAccountEmail,
  yaleAccountPhone,
}) => {
  if (!yaleAccountConfirmed && !yaleAccountExists) {
    return steps.Two;
  }

  if (
    !yaleAccountConfirmed &&
    yaleAccountExists &&
    yaleAccountEmail &&
    !yaleAccountPhone
  ) {
    return steps.Two;
  }

  if (
    !yaleAccountConfirmed &&
    yaleAccountExists &&
    yaleAccountEmail &&
    yaleAccountPhone
  ) {
    return steps.Three;
  }

  if (yaleAccountConfirmed && yaleAccountExists) {
    return 0;
  }
  return 0;
};

export const formatPhoneNumberByMask = (phoneNumber) =>
  phoneNumber && phoneNumber !== ""
    ? phoneNumber.slice(2).replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
    : "";

export const trimStringProps = (obj) =>
  Object.keys(obj).reduce((acc, key) => {
    acc[key] = typeof obj[key] === "string" ? obj[key].trim() : obj[key];
    return acc;
  }, {});
