import { createAction, createReducer } from "@reduxjs/toolkit";

/**
 * @template T
 * @typedef {import('types/stateTypes').PayloadPreparator<T>} PayloadPreparator
 */

/** @type {{ property: number; finance: number; debt: number }} */
const initialState = {
  property: 0,
  finance: 0,
  debt: 0,
};

export const setBuidingIdForReturn = createAction(
  "buildingIdForReturn/set",
  /**
   * @type {PayloadPreparator<{ key: "property"|"finance"|"debt"; id: number; }>}
   */
  (data) => ({ payload: data })
);
export const resetBuildingIdForReturn = createAction(
  "buildingIdForReturn/reset"
);

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(setBuidingIdForReturn, (state, { payload }) => {
    state[payload.key] = payload.id;
  });
  builder.addCase(resetBuildingIdForReturn, () => initialState);
});

export default reducer;
