import { createAction, createReducer } from "@reduxjs/toolkit";
/**
 * @template T
 * @typedef {import('types/stateTypes').PayloadPreparator<T>} PayloadPreparator
 */

/** @typedef {import("types/commonTypes").NewBuildingData} NewBuildingData */
/** @typedef {import("types/commonTypes").UnitSet} UnitSet */
/** @type {{  step: number; building: NewBuildingData; }} */
const initialState = {
  step: 0,
  building: {
    name: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    propertyManagers: [],
    publicPlaces: [],
    unitSets: [],
  },
};

export const startOnboarding = createAction("onboarding/start");
export const nextStep = createAction("onboarding/nextStep");
export const previousStep = createAction("onboarding/previousStep");
export const resetOnboarding = createAction("onboarding/reset");

export const setBuildingData = createAction(
  "onboarding/setBuildingData",
  /**
   * @type {PayloadPreparator<Partial<NewBuildingData>>}
   */
  (buildingData) => ({ payload: buildingData })
);

export const setUnitSet = createAction(
  "onboarding/setUnitSet",
  /**
   * @type {PayloadPreparator<UnitSet>}
   */
  (newUnitSet) => ({ payload: newUnitSet })
);

export const setPropertyManagers = createAction(
  "onboarding/setPropertyManagers",
  /**
   * @type {PayloadPreparator<number[]>}
   */
  (newPropertyManagers) => ({ payload: newPropertyManagers })
);

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(startOnboarding, (state) => {
      state.step = 1;
    })
    .addCase(nextStep, (state) => {
      state.step += 1;
    })
    .addCase(previousStep, (state) => {
      if (state.step) {
        state.step -= 1;
      }
    })
    .addCase(resetOnboarding, (state) => {
      state.building = initialState.building;
      state.step = 0;
    })
    .addCase(setBuildingData, (state, { payload }) => {
      state.building = {
        ...state.building,
        ...payload,
      };
    })
    .addCase(setUnitSet, (state, { payload }) => {
      state.building.unitSets = [payload];
    })
    .addCase(setPropertyManagers, (state, { payload }) => {
      state.building.propertyManagers = payload;
    });
});

export default reducer;
