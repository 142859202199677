import React, { useContext } from "react";
import { GlobalContext } from "store/globalContext";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { confirmationConfig } from "models/confirmationConfig";
import { closeConfirmation } from "store/reducers/confirmationReducer";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Stack,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as CSC from "SC";
import { useIsMobilePortraitScreen } from "hooks/useIsMobilePortraitScreen";

const maxWidthSx = { maxWidth: "400px" };

export const Confirmation = () => {
  const dispatch = useAppDispatch();
  const { modalConfirmationType } = useAppSelector(
    (state) => state.confirmation
  );
  const isMobilePortraitScreen = useIsMobilePortraitScreen();

  const isGlobalStateBasedPresent = modalConfirmationType !== "NONE";

  const { confirmationWithCallback, closeConfirmationWithCallback } =
    useContext(GlobalContext);
  const isGlobalContextBasedPresent = !!confirmationWithCallback?.title;
  const isConditionalCallbackPresent =
    !!confirmationWithCallback?.conditionalCallbackFn;

  const contextBasedData = isGlobalContextBasedPresent
    ? {
        title: confirmationWithCallback?.title,
        message: confirmationWithCallback?.message,
        abort: () => {
          if (confirmationWithCallback?.cancelFn) {
            confirmationWithCallback.cancelFn();
          }
          closeConfirmationWithCallback();
        },
        proceed: () => {
          confirmationWithCallback?.conditionalCallbackFn &&
            confirmationWithCallback.conditionalCallbackFn();
          setTimeout(() => confirmationWithCallback?.callbackFn());
          closeConfirmationWithCallback();
        },
        proceedWithoutConditionalCallback: () => {
          setTimeout(() => confirmationWithCallback?.callbackFn());
          closeConfirmationWithCallback();
        },
        extraData: confirmationWithCallback?.extraData,
        buttonLabels: confirmationWithCallback?.buttonLabels,
        buttonColors: confirmationWithCallback?.buttonColors,
        closeIcon: confirmationWithCallback?.closeIcon ?? true,
      }
    : {};

  const {
    title = undefined,
    message = undefined,
    proceed = () => null,
    proceedWithoutConditionalCallback = () => null,
    abort = () => null,
    extraData = undefined,
    buttonLabels = [],
    buttonColors = [],
    closeIcon = true,
  } = isGlobalStateBasedPresent
    ? {
        title: confirmationConfig[modalConfirmationType]?.title,
        message: confirmationConfig[modalConfirmationType]?.message,
        abort: () => dispatch(closeConfirmation()),
        proceed: () => {
          confirmationConfig[modalConfirmationType]?.dispatchAction &&
            dispatch(
              confirmationConfig[modalConfirmationType].dispatchAction()
            );
          dispatch(closeConfirmation());
        },
      }
    : contextBasedData;

  const mobileSx = isMobilePortraitScreen
    ? {
        padding: "0 20px",
      }
    : {};

  const mobileTitleSx = isMobilePortraitScreen
    ? {
        padding: "20px 40px 0 40px",
        fontSize: "1.2rem !important",
      }
    : {};

  const mobileButtonsSx = isMobilePortraitScreen
    ? {
        padding: "0 40px 20px 40px",
      }
    : {};

  return (
    (isGlobalStateBasedPresent || isGlobalContextBasedPresent) && (
      <Dialog
        open
        aria-labelledby="confirmation-title"
        aria-describedby="confirmation-description"
      >
        {closeIcon && (
          <Stack
            alignItems={"flex-end"}
            sx={{ margin: 0, position: "absolute", top: 0, right: 0 }}
          >
            <IconButton aria-label="close" onClick={abort}>
              <CloseIcon fontSize="large" />
            </IconButton>
          </Stack>
        )}
        {title && (
          <DialogTitle
            id="confirmation-title"
            sx={{ ...maxWidthSx, ...mobileTitleSx }}
          >
            {title}
          </DialogTitle>
        )}
        {message && (
          <DialogContent
            id="confirmation-description"
            sx={{ ...maxWidthSx, ...mobileSx }}
          >
            {message}
            {extraData && (
              <Box>
                {Array.isArray(extraData) ? extraData.join(", ") : extraData}
              </Box>
            )}
            <CSC.EmptySpace />
          </DialogContent>
        )}
        <DialogActions sx={{ ...maxWidthSx, ...mobileButtonsSx }}>
          <CSC.DialogButton
            variant="outlined"
            color={buttonColors[0]}
            onClick={abort}
          >
            {buttonLabels[0] ?? "Cancel"}
          </CSC.DialogButton>
          &nbsp;
          {isConditionalCallbackPresent ? (
            <>
              <CSC.DialogButton
                variant="contained"
                color={buttonColors[1] ?? "warning"}
                onClick={proceedWithoutConditionalCallback}
              >
                {buttonLabels[1] ?? "Discard & Proceed"}
              </CSC.DialogButton>
              &nbsp;
              <CSC.DialogButton
                variant="contained"
                color={buttonColors[2] ?? "success"}
                onClick={proceed}
              >
                {buttonLabels[2] ?? "Save & Proceed"}
              </CSC.DialogButton>
            </>
          ) : (
            <>
              <CSC.DialogButton
                variant="contained"
                color={buttonColors[1]}
                onClick={proceed}
              >
                {buttonLabels[1] ?? "Proceed"}
              </CSC.DialogButton>
            </>
          )}
        </DialogActions>
      </Dialog>
    )
  );
};
