export const HomeIcon = ({ color = "black" }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.4997 15.8333H19.1663V17.5H0.833008V15.8333H2.49967V3.33333C2.49967 3.11232 2.58747 2.90036 2.74375 2.74408C2.90003 2.5878 3.11199 2.5 3.33301 2.5H11.6663C11.8874 2.5 12.0993 2.5878 12.2556 2.74408C12.4119 2.90036 12.4997 3.11232 12.4997 3.33333V15.8333H15.833V9.16667H14.1663V7.5H16.6663C16.8874 7.5 17.0993 7.5878 17.2556 7.74408C17.4119 7.90036 17.4997 8.11232 17.4997 8.33333V15.8333ZM4.16634 4.16667V15.8333H10.833V4.16667H4.16634ZM5.83301 9.16667H9.16634V10.8333H5.83301V9.16667ZM5.83301 5.83333H9.16634V7.5H5.83301V5.83333Z"
      fill={color}
    />
  </svg>
);

export const PlusIcon = ({ color = "black" }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8337 10.8327H10.8337V15.8327H9.16699V10.8327H4.16699V9.16602H9.16699V4.16602H10.8337V9.16602H15.8337V10.8327Z"
      fill={color}
    />
  </svg>
);

export const LockIcon = ({ color = "black" }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 8.25H5.5C5.10218 8.25 4.72064 8.40804 4.43934 8.68934C4.15804 8.97064 4 9.35218 4 9.75V15C4 15.3978 4.15804 15.7794 4.43934 16.0607C4.72064 16.342 5.10218 16.5 5.5 16.5H14.5C14.8978 16.5 15.2794 16.342 15.5607 16.0607C15.842 15.7794 16 15.3978 16 15V9.75C16 9.35218 15.842 8.97064 15.5607 8.68934C15.2794 8.40804 14.8978 8.25 14.5 8.25H13M7 8.25V6C7 5.20435 7.31607 4.44129 7.87868 3.87868C8.44129 3.31607 9.20435 3 10 3C10.7956 3 11.5587 3.31607 12.1213 3.87868C12.6839 4.44129 13 5.20435 13 6V8.25M7 8.25H13M10 11.25V13.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const AccountIcon = ({ color = "black" }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.3111 16.4066C17.24 14.5267 15.5674 13.0619 13.5627 12.248C14.5596 11.5003 15.296 10.4579 15.6676 9.26842C16.0392 8.07892 16.0271 6.80267 15.633 5.62044C15.2389 4.43822 14.4829 3.40995 13.4719 2.6813C12.461 1.95264 11.2464 1.56055 10.0002 1.56055C8.75401 1.56055 7.53942 1.95264 6.52847 2.6813C5.51752 3.40995 4.76146 4.43822 4.36738 5.62044C3.9733 6.80267 3.96119 8.07892 4.33276 9.26842C4.70433 10.4579 5.44075 11.5003 6.43769 12.248C4.43294 13.0619 2.76038 14.5267 1.68925 16.4066C1.62288 16.5134 1.57864 16.6323 1.55917 16.7565C1.5397 16.8807 1.54539 17.0075 1.57591 17.1294C1.60642 17.2513 1.66114 17.3658 1.7368 17.4662C1.81247 17.5665 1.90753 17.6506 2.01635 17.7135C2.12517 17.7764 2.24552 17.8167 2.37025 17.8322C2.49497 17.8476 2.62153 17.8378 2.74239 17.8033C2.86325 17.7689 2.97595 17.7105 3.07379 17.6316C3.17162 17.5527 3.2526 17.4549 3.31191 17.3441C4.72753 14.8973 7.22753 13.4379 10.0002 13.4379C12.7728 13.4379 15.2728 14.898 16.6885 17.3441C16.817 17.5508 17.0209 17.6994 17.2569 17.7585C17.493 17.8176 17.7428 17.7825 17.9536 17.6608C18.1643 17.539 18.3194 17.3401 18.3861 17.1061C18.4529 16.872 18.426 16.6212 18.3111 16.4066ZM5.93769 7.50039C5.93769 6.6969 6.17595 5.91146 6.62234 5.24339C7.06874 4.57531 7.70321 4.05461 8.44554 3.74713C9.18786 3.43965 10.0047 3.3592 10.7927 3.51595C11.5808 3.6727 12.3047 4.05962 12.8728 4.62777C13.441 5.19592 13.8279 5.91979 13.9846 6.70784C14.1414 7.49588 14.0609 8.31272 13.7534 9.05504C13.446 9.79737 12.9253 10.4318 12.2572 10.8782C11.5891 11.3246 10.8037 11.5629 10.0002 11.5629C8.92313 11.5617 7.89053 11.1332 7.12894 10.3716C6.36734 9.61004 5.93893 8.57745 5.93769 7.50039Z"
      fill={color}
    />
  </svg>
);

export const MenuArrowIcon = ({ color = "black" }) => (
  <svg
    width="6"
    height="10"
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.659675 9.35355C0.446775 9.15829 0.446775 8.84171 0.659675 8.64645L4.25 5.35355C4.4629 5.15829 4.4629 4.84171 4.25 4.64645L0.659675 1.35355C0.446776 1.15829 0.446776 0.841709 0.659675 0.646446C0.872575 0.451184 1.21775 0.451185 1.43065 0.646446L5.02098 3.93934C5.65967 4.52513 5.65968 5.47487 5.02098 6.06066L1.43065 9.35355C1.21775 9.54882 0.872574 9.54882 0.659675 9.35355Z"
      fill={color}
      fillOpacity="0.2"
    />
  </svg>
);

export const LogoutIcon = ({ color = "black" }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.33301 10H16.6663M16.6663 10L14.1663 7.5M16.6663 10L14.1663 12.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.33301 10.0007C3.33301 8.23254 4.03539 6.53685 5.28563 5.28661C6.53587 4.03636 8.23156 3.33398 9.99967 3.33398M9.99967 16.6673C9.00016 16.6682 8.01328 16.4439 7.11233 16.0111C6.21138 15.5783 5.4195 14.9481 4.79551 14.1673"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export const FinanceIcon = ({ color = "black" }) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.27814 0C9.05441 0 8.83984 0.0888754 8.68165 0.247075C8.52345 0.405274 8.43457 0.619838 8.43457 0.843566V7.59209C8.43457 7.81582 8.52345 8.03039 8.68165 8.18858C8.83984 8.34678 9.05441 8.43566 9.27814 8.43566H16.0267C16.2504 8.43566 16.465 8.34678 16.6232 8.18858C16.7814 8.03039 16.8702 7.81582 16.8702 7.59209C16.8702 5.57855 16.0704 3.64747 14.6466 2.22367C13.2228 0.799879 11.2917 0 9.27814 0ZM10.1217 6.74853V1.74618C11.3838 1.92806 12.5532 2.51364 13.4549 3.41533C14.3566 4.31702 14.9422 5.48638 15.124 6.74853H10.1217Z"
      fill={color}
    />
    <path
      d="M15.875 10.1753C15.7703 10.1379 15.6593 10.1216 15.5483 10.1272C15.4372 10.1328 15.3284 10.1603 15.2281 10.2082C15.1278 10.256 15.0379 10.3232 14.9636 10.4058C14.8893 10.4885 14.8321 10.585 14.7952 10.6899C14.415 11.7645 13.7689 12.7254 12.917 13.4828C12.0651 14.2401 11.0352 14.7694 9.92347 15.0212C8.81173 15.2729 7.65432 15.2389 6.55925 14.9224C5.46418 14.606 4.46711 14.0172 3.66109 13.2112C2.85506 12.4052 2.26634 11.4081 1.94984 10.313C1.63334 9.21796 1.59938 8.06056 1.85114 6.94882C2.10289 5.83707 2.63215 4.80721 3.38953 3.95531C4.14692 3.10342 5.10775 2.45725 6.18238 2.07709C6.39381 2.00326 6.56724 1.84847 6.66453 1.64677C6.76183 1.44506 6.77501 1.21297 6.70118 1.00155C6.62735 0.790126 6.47255 0.616691 6.27085 0.519399C6.06915 0.422106 5.83705 0.408925 5.62563 0.482755C4.28187 0.957495 3.08028 1.76494 2.13303 2.82974C1.18577 3.89454 0.52373 5.18197 0.20869 6.57187C-0.106351 7.96178 -0.064119 9.40884 0.331444 10.778C0.727008 12.1472 1.46301 13.3938 2.47075 14.4015C3.47849 15.4093 4.72512 16.1453 6.09428 16.5408C7.46345 16.9364 8.91051 16.9786 10.3004 16.6636C11.6903 16.3486 12.9777 15.6865 14.0425 14.7393C15.1073 13.792 15.9148 12.5904 16.3895 11.2467C16.4625 11.0363 16.4493 10.8057 16.3529 10.605C16.2566 10.4043 16.0847 10.2499 15.875 10.1753Z"
      fill={color}
    />
  </svg>
);

export const PapReportIcon = ({ color = "black" }) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.63333 7.75833C7.35 5.08333 7.91667 3.33333 9.16667 3.33333C10.4167 3.33333 10.9833 5.08333 11.7 7.75833C12.5 10.7667 13.4167 14.1667 16.6667 14.1667V12.5C14.8333 12.5 14.1667 10.45 13.3083 7.33333C12.5667 4.55 11.7917 1.66667 9.16667 1.66667C6.54167 1.66667 5.76667 4.55 5.025 7.33333C4.19167 10.45 3.5 12.5 1.66667 12.5V0H0V16.6667H16.6667V15H1.66667V14.1667C4.91667 14.1667 5.83333 10.7667 6.63333 7.75833Z"
      fill={color}
    />
  </svg>
);
