import { useCallback, useState } from "react";

export const useWarnings = () => {
  const [warning, setWarning] = useState({});

  const showWarning = useCallback(
    ({ title, content, buttonLabel = "Got it" }) => {
      setWarning({
        title,
        content,
        buttonLabel,
      });
    },
    []
  );

  const hideWarning = useCallback(() => {
    setWarning({});
  }, []);

  return {
    warning,
    showWarning,
    hideWarning,
  };
};
