import { IconButton, InputAdornment, TextField } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { customColors } from "models/customColors";

export const SearchField = ({
  label = "Search",
  placeholder = "Search",
  search,
  handleChangeSearch,
  clearSearch,
}) => {
  return (
    <TextField
      label={label}
      placeholder={placeholder}
      value={search}
      onChange={handleChangeSearch}
      size={"small"}
      sx={{
        flex: 1,
        backgroundColor: search ? customColors.menu.selected : "white",
        borderRadius: "12px",
        "& .MuiInputBase-root": {
          paddingRight: "0.3em",
        },
      }}
      InputLabelProps={{
        sx: {
          paddingTop: "4px",
        },
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {search && (
              <IconButton onClick={clearSearch}>
                <ClearIcon />
              </IconButton>
            )}
          </InputAdornment>
        ),
        autoComplete: "off",
      }}
      inputProps={{
        sx: {
          height: "32px",
          color: search ? customColors.text.highlighted : "black",
        },
        autoComplete: "off",
      }}
      autoComplete="off"
    />
  );
};
