import React from "react";
import { Box, styled } from "@mui/material";

const Container = styled(Box)`
  & iframe {
    width: 169px;
    height: 93px;
  }
`;

/** @param {{ embedId: string; title?: string }} props */
export const YoutubeEmbed = ({ embedId, title = "Embedded youtube" }) => (
  <Container>
    <iframe
      width="853"
      height="480"
      src={`https://www.youtube.com/embed/${embedId}?rel=0&modestbranding=1&showinfo=0`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title={title}
    />
  </Container>
);
