import { configureStore } from "@reduxjs/toolkit";
import reducer from "store/reducers/rootReducer";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import * as authApi from "api/authApi";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["onboarding", "buildingIdForReturn"],
};

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      thunk: {
        extraArgument: {
          /* TODO - add API object here */
          authApi,
        },
      },
    }),
  devTools: process.env.NODE_ENV === "development",
  enhancers: [],
});

export const getStoreState = () => {
  return store.getState();
};

export default store;
