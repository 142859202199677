import React, { lazy, useEffect, useState } from "react";
import { GlobalContext } from "store/globalContext";
import { useConfirmationsWithCallback } from "hooks/useConfirmationWithCallback";
import { useWarnings } from "hooks/useWarnings";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { localStorageNameEnum } from "models/constants";
import { restoreAuth } from "store/reducers/userReducer";
import { ModalSpinner } from "components/ModalSpinner";
import * as FP from "utils/fp-js";
import { SuspenseWrapper } from "UI/SuspenseWrapper";
import SuperAdmin from "routers/SuperAdmin";

function onOrientationChange() {
  const viewportmeta = document.querySelector('meta[name="viewport"]');
  if (window?.screen?.orientation?.angle === 0) {
    // @ts-ignore
    viewportmeta.content = "width=device-width, user-scalable=no";
    return;
  }

  // @ts-ignore
  viewportmeta.content = "width=device-width, initial-scale=1";
}

window.addEventListener("orientationchange", onOrientationChange);
onOrientationChange();

const APP_LOADING_DELAY_IN_MS = 300;

// const LandloardStripeRegistration = lazy(() =>
//   // @ts-ignore
//   import("./routers/LandloardStripeRegistration")
// );

// @ts-ignore
const Landloard = lazy(() => import("./routers/Landloard"));

const PropertyManagerPasswordNedeed = lazy(() =>
  // @ts-ignore
  import("./routers/PropertyManagerPasswordNedeed")
);
// @ts-ignore
const PropertyManager = lazy(() => import("./routers/PropertyManager"));
// @ts-ignore
const Auth = lazy(() => import("./routers/Auth"));

export const App = () => {
  const dispatch = useAppDispatch();
  const confirmationWithCallbackProps = useConfirmationsWithCallback();
  const warningProps = useWarnings();
  const [defaultTenantAssignmentData, setDefaultTenantAssignmentData] =
    useState({});

  const authExpiresAt = useAppSelector(
    (state) => state?.user?.access?.expiresAt
  );
  const role = useAppSelector((state) => state.user.role);
  const status = useAppSelector((state) => state.user.status);
  // const stripeAccountId = useAppSelector(
  //   (state) => state.user?.stripeAccountId
  // );
  // const isStripeAccountSkipped = useAppSelector(
  //   (state) => state.user.isStripeAccountSkipped
  // );
  const [isStarting, setIsStarting] = useState(true);

  useEffect(() => {
    const refreshToken = window.localStorage.getItem(
      localStorageNameEnum.AUTH_REFRESH_TOKEN
    );

    if (refreshToken) {
      dispatch(restoreAuth(refreshToken));
    }
    const timeout = setTimeout(
      () => setIsStarting(false),
      APP_LOADING_DELAY_IN_MS
    );

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let timeout;

    if (authExpiresAt) {
      const now = new Date().getTime();
      const refreshIn = authExpiresAt - now - 1000 * 60; // interval of the auth refresh in 1 min before it is expired

      const refreshToken = window.localStorage.getItem(
        localStorageNameEnum.AUTH_REFRESH_TOKEN
      );

      timeout = setTimeout(
        () => dispatch(restoreAuth(refreshToken)),
        refreshIn
      );
    }

    return () => (timeout ? clearTimeout(timeout) : null);
  }, [authExpiresAt, dispatch]);

  if (isStarting) {
    return <ModalSpinner open />;
  }

  return (
    <GlobalContext.Provider
      value={{
        ...confirmationWithCallbackProps,
        ...warningProps,
        defaultTenantAssignmentData,
        setDefaultTenantAssignmentData,
      }}
    >
      {FP.match(true)
        // .on(
        //   !isStripeAccountSkipped && !stripeAccountId && role === "LANDLORD",
        //   <SuspenseWrapper>
        //     <LandloardStripeRegistration />
        //   </SuspenseWrapper>
        // )
        .on(
          role === "SUPER_ADMIN",
          <SuspenseWrapper>
            <SuperAdmin />
          </SuspenseWrapper>
        )
        .on(
          role === "LANDLORD",
          <SuspenseWrapper>
            <Landloard />
          </SuspenseWrapper>
        )
        .on(
          role === "PROPERTY_MANAGER" && status === "PASSWORD_NEEDED",
          <SuspenseWrapper>
            <PropertyManagerPasswordNedeed />
          </SuspenseWrapper>
        )
        .on(
          role === "PROPERTY_MANAGER",
          <SuspenseWrapper>
            <PropertyManager />
          </SuspenseWrapper>
        )
        .otherwise(
          <SuspenseWrapper>
            <Auth />
          </SuspenseWrapper>
        )}
    </GlobalContext.Provider>
  );
};
