import React from "react";
import { MenuItem } from "@mui/material";
/**
 * Returns array of JSX select options for given OptionEnum array
 * @param {{[key:string]: string}} optionsEnum
 * @param {boolean} withNone
 * @returns {JSX.Element[]}
 */
export const getOptionsForSelect = (
  optionsEnum,
  withNone = true,
  doNotSortOptions = false
) => {
  const entries = Object.entries(optionsEnum);

  if (!doNotSortOptions) {
    entries.sort((a, b) => {
      if (typeof a[1] === "string") {
        return a[1] > b[1] ? 1 : -1;
      }
      // @ts-ignore
      return a[1].name > b[1].name ? 1 : -1;
    });
  }

  const options = withNone
    ? [
        <MenuItem key="empty" value="">
          None
        </MenuItem>,
      ]
    : [];
  entries.forEach(([value, label], index) => {
    if (typeof label === "string") {
      options.push(
        <MenuItem key={String(value) + String(index)} value={value}>
          {label}
        </MenuItem>
      );
    } else {
      options.push(
        <MenuItem
          // @ts-ignore
          disabled={label.disabled}
          key={String(value) + String(index)}
          value={value}
        >
          {
            // @ts-ignore
            label.name
          }
        </MenuItem>
      );
    }
  });
  return options;
};
