import React from "react";
import { Stack, styled, Grid } from "@mui/material";
import { SorterIcon } from "UI/SorterIcon";
import { customColors } from "models/customColors";

export const Container = styled(Grid)`
  padding: 1em 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;

  &.no-border {
    border: none;
  }
  &.with-border {
    border: thin solid ${customColors.border};
  }
`;

export const ItemWithoutSorter = styled(Grid)`
  padding: 0 0 0 1em;
  font-size: 1em;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  user-select: none;

  @media (max-width: 1600px) {
    font-size: 0.95em;
  }

  @media (max-width: 1440px) {
    font-size: 0.9em;
  }

  @media (max-width: 1380px) {
    font-size: 0.85em;
  }

  @media (max-width: 1280px) {
    font-size: 0.8em;
  }

  @media (max-width: 1024px) {
    font-size: 0.7em;
  }
`;

export const Item = styled(ItemWithoutSorter)`
  cursor: pointer;

  &:hover {
    color: ${customColors.text.highlighted};
  }
`;

export const DataGridHeader = ({
  columns,
  sorter,
  handleSetSorter,
  noSorter,
  noHeaderBorder,
}) => {
  return (
    <Container
      container
      className={noHeaderBorder ? "no-border" : "with-border"}
    >
      {columns.map(({ name, sorterName = name, label, xs, align = "left", sortable = true }) => {
        const justifyContent = {
          left: "flex-start",
          center: "center",
          right: "flex-end",
        };

        if (!xs) return null;

        const key = `header-${name}`;

        return noSorter || !sortable ? (
          <ItemWithoutSorter key={key} item xs={xs}>
            <Stack
              direction={"row"}
              justifyContent={justifyContent[align]}
              alignItems={"center"}
            >
              {label}
            </Stack>
          </ItemWithoutSorter>
        ) : (
          <Item
            key={key}
            item
            xs={xs}
            onClick={sorterName ? () => handleSetSorter(sorterName) : () => null}
          >
            <Stack
              direction={"row"}
              justifyContent={justifyContent[align]}
              alignItems={"center"}
            >
              {label}
              {sorterName && <SorterIcon sorter={sorter} sorterName={sorterName} />}
            </Stack>
          </Item>
        );
      })}
      <ItemWithoutSorter></ItemWithoutSorter>
    </Container>
  );
};
