import { Stack } from "@mui/material";
import { useIsMobilePortraitScreen } from "hooks/useIsMobilePortraitScreen";

export const DialogContentWrapper = ({ children, horizontalPadding = 0, mt = 3, mb = 0 }) => {
  const isMobilePortraitScreen = useIsMobilePortraitScreen();

  const sx = isMobilePortraitScreen
    ? { padding: `0 ${20 + horizontalPadding}px` }
    : { padding: `0 ${80 + horizontalPadding}px` };

  return (
    <Stack mt={mt} mb={mb} sx={sx}>
      {children}
    </Stack>
  );
};
