import { Box, Stack, styled } from "@mui/material";
import { customColors } from "models/customColors";
import { Link } from "react-router-dom";

export const Container = styled(Stack)`
  flex: 1 1 auto;
  flex-direction: row;
  height: 100dvh;

  justify-content: stretch;
  align-items: stretch;
  overflow-y: hidden;
`;

export const Content = styled(Stack)`
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;

  overflow-y: hidden;
`;

export const Navigation = styled(Stack)`
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: space-between;
  padding: 1em;
  width: 220px;
  border-right: thin solid rgba(0, 0, 0, 0.1);
`;

export const OutletHolder = styled(Stack)`
  flex: 1 1 auto;
  justify-content: flex-start;
  align-items: stretch;
  overflow-y: auto;
  background-color: #f6f6f6;
`;

export const UserName = styled(Box)`
  margin-bottom: 0;
  font-size: 0.9em;
`;

export const Role = styled(Box)`
  font-size: 0.7em;
  color: ${customColors.text.inactive};
`;

export const EditProfileLink = styled(Link)`
  text-decoration: none;
  color: #007aff;
  cursor: pointer;
  font-size: 0.7em;
`;

export const Menu = styled(Stack)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Header = styled(Stack)`
  flex: 0 0 auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 50px);
  height: 76px;
  padding: 0 25px;
  border-bottom: thin solid rgba(0, 0, 0, 0.1);
  background-color: white;
`;

export const MobileHeader = styled(Stack)`
  flex: 0 0 auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 40px);
  height: 56px;
  padding: 0 20px;
  border-bottom: thin solid rgba(0, 0, 0, 0.1);
  background-color: white;
`;

export const Notifications = styled(Box)`
  padding: 22px;
  width: 150px;
  border-left: thin solid rgba(0, 0, 0, 0.1);
  color: ${customColors.text.inactive};
`;

export const MobileNavigation = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  border-right: thin solid rgba(0, 0, 0, 0.1);
  background-color: #ffffff;

  z-index: 1000;
`;

export const Overlay = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 999;
`;
