import React from "react";
import { Stack, Box, styled, Grid } from "@mui/material";

import { DataGridHeader } from "UI/DataGridHeader";
import { customColors } from "models/customColors";

const DatagridContent = styled(Box)`
  flex: 1 1 auto;
  overflow-y: auto;
  scrollbar-width: thin;
  background-color: white;
  border: thin solid ${customColors.border};

  &.no-header-border {
    border-radius: 15px;
  }

  &.with-header-border {
    border-top: none;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
`;

const RowContainer = styled(Grid)`
  padding: 0;
  border-bottom: thin solid ${customColors.border};
  overflow-x: hidden;

  &.clickable {
    cursor: pointer;
  }

  &.clickable:hover {
    cursor: pointer;
    background-color: #f0f0fa;
  }
`;

const RowGrid = styled(Grid)`
  padding: 1em 0 1em 1em;
  word-wrap: break-word;

  font-size: 1em;

  @media (max-width: 1600px) {
    font-size: 0.95em;
  }

  @media (max-width: 1440px) {
    font-size: 0.9em;
  }

  @media (max-width: 1380px) {
    font-size: 0.85em;
  }

  @media (max-width: 1280px) {
    font-size: 0.8em;
  }

  @media (max-width: 1024px) {
    font-size: 0.7em;
  }
`;

/** @type {import("./DataGrid.types").DataGridComponent} */
export const DataGrid = ({
  columns = [],
  rows = [],
  sorter = {},
  handleSetSorter = () => null,
  minWidth = "100%",
  noSorter = false,
  noHeaderBorder = false,
  handleClickRow,
}) => (
  <Stack sx={{ flex: "1 1 auto", overflowY: "hidden", minWidth }}>
    <DataGridHeader
      columns={columns}
      sorter={sorter}
      handleSetSorter={handleSetSorter}
      noSorter={noSorter}
      noHeaderBorder={noHeaderBorder}
    />
    <DatagridContent
      className={noHeaderBorder ? "no-header-border" : "with-header-border"}
    >
      {rows?.length === 0 && (
        <RowContainer container alignItems={"center"}>
          <RowGrid item xs={12} sx={{ textAlign: "center" }}>
            No data
          </RowGrid>
        </RowContainer>
      )}
      {rows?.map((row) => (
        <RowContainer
          key={`container-${row.id}`}
          className={handleClickRow ? "clickable" : ""}
          container
          alignItems={"center"}
          onClick={handleClickRow ? () => handleClickRow(row) : undefined}
        >
          {columns?.map(
            ({ xs = 0, name, label = "", render, align = "left" }) => {
              if (xs) {
                return (
                  <RowGrid
                    key={`${row.id}${name}`}
                    item
                    xs={xs}
                    sx={{ textAlign: align }}
                  >
                    {render ? render(row) : row[name]}
                  </RowGrid>
                );
              }
              return null;
            }
          )}
        </RowContainer>
      ))}
    </DatagridContent>
  </Stack>
);
