import {
  createAction,
  createAsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";
import { showError, hideError } from "./errorReducer";
import { localStorageNameEnum } from "models/constants";
import { showSpinner, hideSpinner } from "./spinnerReducer";
import {
  resetIsRefreshPending,
  setIsRefreshPending,
} from "./isRefreshPendingReducer";

/**
 * @template T
 * @typedef {import('types/stateTypes').PayloadPreparator<T>} PayloadPreparator
 */

/** @type {import("types/stateTypes").User} */
export const initialState = {
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  role: undefined,
  access: undefined,
  refresh: undefined,
  status: undefined,
  stripeAccountId: undefined,
  isStripeAccountSkipped: false,
};

export const getAuth = createAsyncThunk(
  "user/getAuth",
  async (
    /** @type {{ username: string; password: string;}} */ credentials,
    thunkAPI
  ) => {
    thunkAPI.dispatch(showSpinner());
    thunkAPI.dispatch(hideError());

    try {
      const { username, password } = credentials;
      // @ts-ignore
      const result = await thunkAPI.extra.authApi.login({ username, password });

      if (result?.error) {
        window.sessionStorage.removeItem(localStorageNameEnum.AUTH_TOKEN);
        thunkAPI.dispatch(showError(result?.error));
        return Promise.reject(result?.error);
      }

      if (result?.value?.access?.token) {
        window.sessionStorage.setItem(
          localStorageNameEnum.AUTH_TOKEN,
          result?.value?.access?.token
        );
        window.localStorage.setItem(
          localStorageNameEnum.AUTH_REFRESH_TOKEN,
          result?.value?.refresh?.token
        );
      }

      return result?.value;
    } catch (e) {
      thunkAPI.dispatch(
        showError(e?.error ?? e?.message ?? "Something went wrong")
      );
      return Promise.reject();
    } finally {
      thunkAPI.dispatch(hideSpinner());
    }
  }
);

export const restoreAuth = createAsyncThunk(
  "user/restoreAuth",
  async (
    /** @type {string} */
    refreshToken,
    thunkAPI
  ) => {
    thunkAPI.dispatch(hideError());

    // @ts-ignore
    const isRefreshPending = thunkAPI.getState()?.isRefreshPending;

    if (isRefreshPending) {
      return Promise.reject();
    }

    thunkAPI.dispatch(setIsRefreshPending());

    try {
      // @ts-ignore
      const result = await thunkAPI.extra.authApi.refresh(refreshToken);

      if (result?.error) {
        window.sessionStorage.removeItem(localStorageNameEnum.AUTH_TOKEN);
        window.localStorage.removeItem(localStorageNameEnum.AUTH_REFRESH_TOKEN);
        return Promise.resolve({});
      }

      if (result?.value?.access?.token) {
        window.sessionStorage.setItem(
          localStorageNameEnum.AUTH_TOKEN,
          result?.value?.access?.token
        );
        window.localStorage.setItem(
          localStorageNameEnum.AUTH_REFRESH_TOKEN,
          result?.value?.refresh?.token
        );
      }

      return Promise.resolve(result?.value);
    } catch (e) {
      thunkAPI.dispatch(
        showError(e?.error ?? e?.message ?? "Something went wrong")
      );
      return Promise.reject();
    } finally {
      thunkAPI.dispatch(hideSpinner());
      thunkAPI.dispatch(resetIsRefreshPending());
    }
  }
);

export const skipStripeAccountCreation = createAction("user/skipStripe");

export const logoutAction = createAction("user/logout");

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      getAuth.fulfilled,
      (
        state,
        {
          payload: {
            userId = "",
            email = "",
            firstName = "",
            lastName = "",
            role,
            access,
            refresh,
            status,
            stripeAccountId,
          },
        }
      ) => {
        const isStripeAccountSkipped = window.localStorage.getItem(
          `${localStorageNameEnum.STRIPE_ACCOUNT_CREATION_SKIPPED}-${userId}`
        );

        state.id = userId;
        state.email = email;
        state.firstName = firstName;
        state.lastName = lastName;
        state.role = role;
        state.access = access;
        state.refresh = refresh;
        state.status = status;
        state.stripeAccountId = stripeAccountId;
        state.isStripeAccountSkipped = isStripeAccountSkipped === "true";
      }
    )
    .addCase(logoutAction, () => {
      window.sessionStorage.removeItem(localStorageNameEnum.AUTH_TOKEN);
      window.localStorage.removeItem(localStorageNameEnum.AUTH_REFRESH_TOKEN);
      return initialState;
    })
    .addCase(skipStripeAccountCreation, (state) => {
      state.isStripeAccountSkipped = true;
    })
    .addCase(
      restoreAuth.fulfilled,
      (
        state,
        {
          payload: {
            userId = "",
            email = "",
            firstName = "",
            lastName = "",
            role,
            access,
            refresh,
            status,
            stripeAccountId,
          },
        }
      ) => {
        const isStripeAccountSkipped = window.localStorage.getItem(
          `${localStorageNameEnum.STRIPE_ACCOUNT_CREATION_SKIPPED}-${userId}`
        );
        state.id = userId;
        state.email = email;
        state.firstName = firstName;
        state.lastName = lastName;
        state.role = role;
        state.access = access;
        state.refresh = refresh;
        state.status = status;
        state.stripeAccountId = stripeAccountId;
        state.isStripeAccountSkipped = isStripeAccountSkipped === "true";
      }
    );
});

export default reducer;
