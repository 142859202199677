import { useState } from "react";
import { useSortAndSearch } from "hooks/useSortAndSearch";
import { useApiRequest } from "api/useApiRequest";
import { getAdminBuildingList } from "api/buildingsApi";
import { useUnrestrictedEffect } from "hooks/useUnrestrictedEffect";
import { defaultFormData } from "components/EditBuildingFeeDialog";

export const useAdminPropertyPage = () => {
  const { callApiRequest } = useApiRequest();

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [selectedBuilding, setSelectedBuilding] = useState(defaultFormData);

  const closeEditBuildingFeeDialog = () => setSelectedBuilding(defaultFormData);

  const {
    sortHandler,
    handleSetSorter,
    search,
    clearSearch,
    handleChangeSearch,
    getSearchFilterHandler,
    sorter,
  } = useSortAndSearch({
    defaultSorter: { name: "name", order: "asc" },
  });

  const handleClickRow = (row) => setSelectedBuilding(row);

  const loadBuildingList = () => {
    callApiRequest({
      apiRequest: getAdminBuildingList,
      onSuccess: (values) => {
        setRows(values);
        setLoading(false);
      },
    });
  };

  useUnrestrictedEffect({
    loadFn: loadBuildingList,
  });

  return {
    search,
    clearSearch,
    handleChangeSearch,
    loading,
    rows,
    handleClickRow,
    handleSetSorter,
    sorter,
    sortHandler,
    getSearchFilterHandler,
    loadBuildingList,
    selectedBuilding,
    setSelectedBuilding,
    closeEditBuildingFeeDialog,
  };
};
