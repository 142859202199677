import { useCallback, useState } from "react";

/** @param {{ defaultSorter?: { name: string; order?: "asc" | "desc"; } }} props */
export const useSortAndSearch = (props = { defaultSorter: { name: "" } }) => {
  const [sorter, setSorter] = useState(props.defaultSorter);

  const [search, setSearch] = useState("");

  /** @param {string} name */
  const handleSetSorter = useCallback(
    (name) => {
      if (name === sorter.name) {
        setSorter({ name, order: sorter.order === "asc" ? "desc" : "asc" });
        return;
      }

      setSorter({ name, order: "asc" });
    },
    [sorter.name, sorter.order]
  );

  const sortHandler = (a, b) => {
    const condition =
      sorter.order === "asc"
        ? a[sorter.name] < b[sorter.name]
        : a[sorter.name] > b[sorter.name];

    return condition ? -1 : 1;
  };

  const clearSearch = () => setSearch("");

  const getSearchFilterHandler = (columns) => (row) => {
    if (search === "") return true;

    const entries = Object.entries(row);
    const searchNames = columns
      .filter(({ searchDisabled = false }) => !searchDisabled)
      .map(({ name }) => name);

    return !!entries.find(
      ([name, value]) =>
        String(value).toLowerCase().includes(search.toLocaleLowerCase()) &&
        searchNames.includes(name)
    );
  };

  const handleChangeSearch = (e) => {
    const value = e?.target?.value;
    setSearch(value);
  };

  return {
    handleSetSorter,
    sortHandler,
    search,
    setSearch,
    handleChangeSearch,
    getSearchFilterHandler,
    clearSearch,
    sorter,
  };
};
