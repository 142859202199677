import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  IconButton,
} from "@mui/material";
import { useIsMobilePortraitScreen } from "hooks/useIsMobilePortraitScreen";

export const DialogWindow = ({
  open,
  title,
  content,
  actions,
  handleClose,
  fullWidthContent = false,
  withCloseButton = false,
  withBackwardButton = false,
  handleBackward = () => null,
}) => {
  const isMobilePortraitScreen = useIsMobilePortraitScreen();

  const mobileSx = isMobilePortraitScreen
    ? {
        padding: "50px 1rem 0 1rem",
      }
    : {};

  const mobileActionsSx = isMobilePortraitScreen
    ? {
        padding: "0 1rem 1rem 1rem",
      }
    : {};

  const mobileTitleSx = isMobilePortraitScreen
    ? {
        fontSize: "1.2rem !important",
      }
    : {};

  return (
    <Dialog open={open} scroll="body">
      {withBackwardButton && (
        <Stack
          alignItems={"flex-start"}
          sx={{
            margin: 0,
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <IconButton aria-label="close" onClick={handleBackward}>
            <ArrowBackIcon fontSize="large" />
          </IconButton>
        </Stack>
      )}
      {withCloseButton && (
        <Stack
          alignItems={"flex-end"}
          sx={{ margin: 0, position: "absolute", top: 0, right: 0 }}
        >
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </Stack>
      )}
      <DialogTitle marginBottom={2} sx={{ ...mobileSx, ...mobileTitleSx }}>
        {title}
      </DialogTitle>
      {fullWidthContent || isMobilePortraitScreen ? (
        <DialogContent sx={{ padding: "0 1rem" }}>{content}</DialogContent>
      ) : (
        <DialogContent>{content}</DialogContent>
      )}
      <DialogActions sx={{ ...mobileActionsSx, marginTop: 2 }}>
        {actions}
      </DialogActions>
    </Dialog>
  );
};
