import React, { useContext } from "react";
import { GlobalContext } from "store/globalContext";
import * as UI from "UI";
import * as CSC from "SC";

export const WarningDialog = () => {
  const { warning, hideWarning } = useContext(GlobalContext);
  const { title, content, buttonLabel } = warning;

  if (!title || !content) return null;

  return (
    <UI.DialogWindow
      open={true}
      withCloseButton
      handleClose={hideWarning}
      title={title}
      content={content}
      actions={
        <CSC.DialogMainButton variant="contained" onClick={hideWarning}>
          {buttonLabel}
        </CSC.DialogMainButton>
      }
    />
  );
};
