import React, { useContext } from "react";
import { GlobalContext } from "store/globalContext";
import { Divider, InputAdornment, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { DialogWindow } from "UI/DialogWindow";
import * as CSC from "SC";
import { useApiRequest } from "api/useApiRequest";
import { FormMoneyField } from "UI/FormMoneyField";
import { updateBuildingFeeData } from "api/buildingsApi";
import { DialogContentWrapper } from "UI/DialogContentWrapper";
import { FormSelectField } from "UI/FormSelectField";
import { feeValidation, required } from "models/validations";

export const defaultFormData = {
  id: 0,
  name: "",
  processingFee: 0,
  feePayer: "",
};

const payerTypeOptions = [
  {
    value: "TENANT",
    label: "Tenant",
  },
  {
    value: "LANDLORD",
    label: "Landlord",
  },
];

export const EditBuildingFeeDialog = ({
  selectedBuilding,
  handleClose,
  refreshList,
}) => {
  const { openConfirmationWithCallback } = useContext(GlobalContext);
  const { callApiRequest } = useApiRequest();

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: defaultFormData,
    values: selectedBuilding,
  });

  const onSubmit = () => {
    const newValue = getValues();

    newValue.name = newValue.name.trim();

    callApiRequest({
      apiRequest: updateBuildingFeeData,
      params: {
        ...newValue,
      },
      onSuccess: () => {
        handleClose();
        refreshList();
      },
    });
  };

  const closeAndReset = () => {
    if (isDirty) {
      openConfirmationWithCallback({
        title: "Confirmation needed",
        message: "All entered data will be lost. Proceed?",
        callbackFn: () => {
          reset(defaultFormData);
          handleClose();
        },
        closeIcon: false,
      });
      return;
    }

    reset(defaultFormData);
    handleClose();
  };

  return (
    <DialogWindow
      open={!!selectedBuilding?.id}
      withCloseButton
      handleClose={closeAndReset}
      title={
        <>
          Edit{" "}
          <CSC.HighlightedText>{selectedBuilding?.name}</CSC.HighlightedText>
        </>
      }
      fullWidthContent
      content={
        <form>
          <Divider />
          <DialogContentWrapper>
            <Stack gap={2} sx={{ maxWidth: "400px" }}>
              <Stack direction={"row"} gap={2}>
                <Stack>
                  <CSC.InputLabel sx={{ alignSelf: "flex-start" }}>
                    Processing Fee
                  </CSC.InputLabel>
                  <Stack alignItems={"flex-start"}>
                    <FormMoneyField
                      id="fee"
                      name="processingFee"
                      control={control}
                      rules={feeValidation}
                      InputProps={{
                        inputProps: {
                          min: 0,
                          max: 10,
                          step: 0.1,
                        },
                        endAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                </Stack>
                <Stack alignItems={"flex-start"} flex={1}>
                  <CSC.InputLabel htmlFor="feePayer">Fee Payer</CSC.InputLabel>
                  <FormSelectField
                    id="feePayer"
                    name="feePayer"
                    control={control}
                    options={payerTypeOptions}
                    rules={{ required }}
                  />
                </Stack>
              </Stack>
            </Stack>
          </DialogContentWrapper>
        </form>
      }
      actions={
        <CSC.DialogMainButton
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          sx={{ marginTop: 3 }}
          disabled={!isDirty}
        >
          Save Changes
        </CSC.DialogMainButton>
      }
    />
  );
};
