import React, { useState } from "react";
import { Box, Stack, IconButton, styled } from "@mui/material";
import { YoutubeEmbed } from "./YoutubeEmbed";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { customColors } from "models/customColors";

const videoTips = [
  {
    name: "Building Onboarding",
    videoId: "FjHvlnslVPM",
  },
  {
    name: "Lock Assignment",
    videoId: "HjYPR3bAbYE",
  },
  {
    name: "Public Access Points",
    videoId: "JDhKvO5FeNc",
  },
  {
    name: "Tenant Assignment",
    videoId: "WWev5842-jQ",
  },
  {
    name: "Yale - Adding to a unit",
    videoId: "bjKXbf1_09Y",
  },
  {
    name: "Yale - Setting up in the mobile app",
    videoId: "OoKGgjBFMrg",
  },
];

const Container = styled(Stack)`
  align-items: center;
  position: relative;

  border-top: thin solid ${customColors.border};
  border-bottom: thin solid ${customColors.border};
`;

const Title = styled(Box)`
  padding: 10px 0 5px 0;
  font-size: 14px;
  font-weight: bold;
`;

const Name = styled(Box)`
  padding: 0 0 10px 0;
  font-size: 12px;
  font-weight: normal;
`;

const Indexes = styled(Stack)`
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 5px 0 10px 0;

  font-size: 10px;
  font-weight: normal;
  color: ${customColors.text.inactive};
`;

const CurrentIndex = styled(Box)`
  color: ${customColors.text.plain};
`;

export const VideoTips = () => {
  const [isVisible, setIsVisible] = useState(
    window.localStorage.getItem("isTipsVisible") ?? "true"
  );
  const [index, setIndex] = useState(0);

  const increaseIndex = () => {
    const newIndex = index + 1;
    if (newIndex < videoTips.length) {
      setIndex(newIndex);
    }
  };

  const reduceIndex = () => {
    const newIndex = index - 1;
    if (newIndex >= 0) {
      setIndex(newIndex);
    }
  };

  const handleCloseTips = () => {
    window.localStorage.setItem("isTipsVisible", "false");
    setIsVisible("false");
  };

  const handleOpenTips = () => {
    window.localStorage.setItem("isTipsVisible", "true");
    setIsVisible("true");
  };

  if (isVisible === "false") {
    return (
      <Container>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box sx={{ color: customColors.text.inactive }}>Useful Tips</Box>
          <IconButton aria-label="open tips" onClick={handleOpenTips}>
            <InfoIcon fontSize="small" />
          </IconButton>
        </Stack>
      </Container>
    );
  }

  return (
    <Container>
      <Stack
        alignItems={"flex-end"}
        sx={{ margin: 0, position: "absolute", top: 0, right: 0 }}
      >
        <IconButton aria-label="close tips" onClick={handleCloseTips}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Stack>
      <Title>Useful Tips</Title>
      <Name>{videoTips[index].name}</Name>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <IconButton onClick={reduceIndex} disabled={index === 0}>
          <ArrowBackIosIcon fontSize="small" />
        </IconButton>
        <YoutubeEmbed embedId={videoTips[index].videoId} />
        <IconButton
          onClick={increaseIndex}
          disabled={index === videoTips.length - 1}
        >
          <ArrowForwardIosIcon fontSize="small" />
        </IconButton>
      </Stack>
      <Indexes>
        <CurrentIndex>{index + 1}</CurrentIndex>
        <Box>/</Box>
        <Box> {videoTips.length}</Box>
      </Indexes>
    </Container>
  );
};
