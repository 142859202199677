import React from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { hideError } from "store/reducers/errorReducer";
import * as UI from "UI";
import * as CSC from "SC";
import { Typography } from "@mui/material";

export const ErrorDialog = () => {
  const dispatch = useAppDispatch();
  const message = useAppSelector((state) => state.error?.message);

  const closeError = () => dispatch(hideError());

  if (!message) return null;

  return (
    <UI.DialogWindow
      open={true}
      withCloseButton
      handleClose={closeError}
      title={
        <Typography
          variant="h4"
          color={"error"}
          sx={{ fontWeight: "bold", minWidth: "280px" }}
        >
          {"Oops"}
        </Typography>
      }
      content={<Typography color={"error"}>{message.toString()}</Typography>}
      actions={
        <CSC.DialogMainButton variant="contained" onClick={closeError}>
          {"Got it"}
        </CSC.DialogMainButton>
      }
    />
  );
};
