import UnsortedImage from "assets/img/unsorted.svg";
import AscImage from "assets/img/asc.svg";
import DescImage from "assets/img/desc.svg";

export const SorterIcon = ({ sorter, sorterName }) => {
  let image = UnsortedImage;

  if (sorterName === sorter?.name) {
    const order = sorter?.order?.toLowerCase();
    if (order === "asc") {
      image = AscImage;
    } else if (order === "desc") {
      image = DescImage;
    }
  }

  return <img src={image} width="22px" alt="unsorted" />;
};
