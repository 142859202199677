import { Box, Stack, styled, colors } from "@mui/material";

export const Container = styled(Stack)`
  margin: 1em;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: space-between;
  gap: 1em;
`;

export const BlueItem = styled(Box)`
  color: ${colors.blue[800]};
`;

export const TotalUnitsHolder = styled(Box)`
  padding: 8px 12px 8px 12px;
  border-radius: 8px;
  background: ${colors.grey[200]};
`;

export const FreeUnitsHolder = styled(TotalUnitsHolder)`
  background: #f0f4ff;
`;
