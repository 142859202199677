import { httpService } from "./httpService";

/**
 *
 * @template T
 * @typedef {import('types/commonTypes').HttpResult<T>} HttpResult
 */

/**
 * @param {{ landlordId: string; }} props
 * @returns  {HttpResult<(import("types/commonTypes").NameList)>}
 */
export const getBuildingNameList = async ({ landlordId }) =>
  httpService({
    url: `/buildings/names/?landlordId=${landlordId}`,
    options: {
      method: "GET",
    },
  });

/**
 * @param {{ landlordId: string; }} props
 * @returns  {HttpResult<(import("types/commonTypes").BuildingData[])>}
 */
export const getBuildingList = async ({ landlordId }) =>
  httpService({
    url: `/buildings/?landlordId=${landlordId}`,
    options: {
      method: "GET",
    },
  });

/**
 * @param {{ landlordId: string; }} props
 * @returns  {HttpResult<(import("types/commonTypes").BuildingDebtDetails[])>}
 */
export const getBuildingDebtList = async ({ landlordId }) =>
  httpService({
    url: `/debt/buildings/?landlordId=${landlordId}`,
    options: {
      method: "GET",
    },
  });

/**
 * @returns  {HttpResult<(import("types/commonTypes").BuildingData[])>}
 */
export const getAdminBuildingList = async () =>
  httpService({
    url: `/buildings/admin`,
    options: {
      method: "GET",
    },
  });

/**
 * @param {{ buildingId: string; }} props
 * @returns  {HttpResult<(import("types/commonTypes").NameList)>}
 */
export const getUnitNameListBuildingId = async ({ buildingId }) =>
  httpService({
    url: `/buildings/units/names/?buildingId=${buildingId}`,
    options: {
      method: "GET",
    },
  });

/**
 * @param {import("types/commonTypes").NewBuildingData} props
 * @returns  {HttpResult<string>}
 */
export const onboardNewBuilding = async (props) =>
  httpService({
    url: "/buildings/onboard",
    options: {
      method: "POST",
      data: {
        ...props,
      },
    },
  });

/**
 * @param {import("types/commonTypes").UpdatePropertyManagersInBuildingData} props
 * @returns  {HttpResult<string>}
 */
export const updatePropertyManagersInBuilding = async (props) =>
  httpService({
    url: "/buildings/property-managers",
    options: {
      method: "POST",
      data: {
        ...props,
      },
    },
  });

/**
 * @param {Partial<import("types/commonTypes").BuildingData>} props
 * @returns  {HttpResult<string>}
 */
export const updateBuildingData = async (props) =>
  httpService({
    url: "/buildings/update",
    options: {
      method: "POST",
      data: {
        ...props,
      },
    },
  });

/**
 * @param {Partial<import("types/commonTypes").BuildingFeeData>} props
 * @returns  {HttpResult<string>}
 */
export const updateBuildingFeeData = async (props) =>
  httpService({
    url: "/buildings/update/admin",
    options: {
      method: "POST",
      data: {
        ...props,
      },
    },
  });

/**
 * @returns  {HttpResult<(import("types/commonTypes").NameList)>}
 */
export const loadPublicAccessPoint = async () =>
  httpService({
    url: `/public-access-points`,
    options: {
      method: "GET",
    },
  });

/**
 * @param {{ name: string; }} props
 * @returns  {HttpResult<{ id: string; name: string; }[]>}
 */
export const createPublicAccessPoint = async ({ name }) =>
  httpService({
    url: `/public-access-points/create`,
    options: {
      method: "POST",
      data: {
        name,
      },
    },
  });

/**
 * @param {string} buildingId
 * @returns  {HttpResult<(import("types/commonTypes").BuildingData)>}
 */
export const getBuildingDetailsById = async (buildingId) =>
  httpService({
    url: `/buildings/details/?buildingId=${buildingId}`,
    options: {
      method: "GET",
    },
  });

/**
 * @param {import("types/commonTypes").Unit} props
 * @returns  {HttpResult<string>}
 */
export const updateUnit = async (props) =>
  httpService({
    url: "/units/update",
    options: {
      method: "POST",
      data: {
        ...props,
      },
    },
  });

/**
 * @param {string} buildingId
 * @returns  {HttpResult<(import("types/commonTypes").NameList)>}
 */
export const getPublicAccessPointById = async (buildingId) =>
  httpService({
    url: `/buildings/public-access-points/?buildingId=${buildingId}`,
    options: {
      method: "GET",
    },
  });

/**
 * @param {string} buildingId
 * @returns  {HttpResult<(import("types/commonTypes").NameList)>}
 */
export const getPublicPlacesByBuildingId = async (buildingId) =>
  httpService({
    url: `/buildings/public-units/?buildingId=${buildingId}`,
    options: {
      method: "GET",
    },
  });

/**
 * @param {import("types/commonTypes").AddNewUnitsPayload} props
 * @returns  {HttpResult<string>}
 */
export const addNewUnitSets = async (props) =>
  httpService({
    url: "/units/create",
    options: {
      method: "POST",
      data: {
        ...props,
      },
    },
  });

/**
 * @param {import("types/commonTypes").AddNewPublicUnitPayload} props
 * @returns  {HttpResult<string>}
 */
export const addNewPublicUnit = async (props) =>
  httpService({
    url: "/units/create-public-unit",
    options: {
      method: "POST",
      data: {
        ...props,
      },
    },
  });

/**
 * @param {string} unitId
 * @returns  {HttpResult<string>}
 */
export const deleteUnit = async (unitId) =>
  httpService({
    url: `/units/delete/?unitId=${unitId}`,
    options: {
      method: "DELETE",
    },
  });

/**
 * @param {import("types/commonTypes").BuildingSearch} data
 * @returns  {HttpResult<(import("types/commonTypes").BuildingSearchResponse)>}
 */
export const getPaymentsByBuildingId = async (data) =>
  httpService({
    url: `/financial/search-payment`,
    options: {
      method: "POST",
      data,
    },
  });

/**
 * @param {import("types/commonTypes").BuildingSearch} data
 * @returns  {HttpResult<(import("types/commonTypes").BuildingDebtsSearchResponse)>}
 */
export const getDebtsByBuildingId = async (data) =>
  httpService({
    url: `/debt/search-debts`,
    options: {
      method: "POST",
      data,
    },
  });


/**
 * @param {string} buildingId
 * @returns  {HttpResult<({pendingAmount: number; failedAmount:number; })>}
 */
export const getBuildingPaymentStatistics = async (buildingId) =>
  httpService({
    url: `/financial/statuses-statistic/?buildingId=${buildingId}`,
    options: {
      method: "GET",
    },
  });

/**
 * @param {import("types/commonTypes").BuildingPapReportsRequest} data
 * @returns  {HttpResult<(import("types/commonTypes").BuildingPapReportsResponse)>}
 */
export const getPapReport = async (data) =>
  httpService({
    url: `/pap-reports/search`,
    options: {
      method: "POST",
      data,
    },
  });
