import { httpService } from "./httpService";

/**
 *
 * @template T
 * @typedef {import('types/commonTypes').HttpResult<T>} HttpResult
 */

/**
 * @typedef {import('types/commonTypes').Auth} Auth
 * @param {{
 *  username: string;
 *  password: string;
 *  firstName: string;
 *  lastName: string;
 *  }} props
 * @returns  {HttpResult<Auth>}
 */
export const addUser = async ({ username, password, firstName, lastName }) =>
  httpService({
    url: `/auth/register/`,
    options: {
      method: "POST",
      data: {
        username,
        password,
        firstName,
        lastName,
      },
    },
  });

/**
 * @param {{ username: string; password: string; }} props
 * @returns  {HttpResult<Auth>}
 */
export const login = async ({ username, password }) =>
  httpService({
    url: "/auth/login",
    options: {
      method: "POST",
      data: {
        username,
        password,
      },
    },
  });

/**
 * @param {string} token
 * @returns  {HttpResult<Auth>}
 */
export const confirmRegistration = async (token) =>
  httpService({
    url: "/auth/confirm-registration",
    options: {
      method: "POST",
      data: {
        token,
      },
    },
  });

/**
 * @param {string} token
 * @returns  {HttpResult<Auth>}
 */
export const refresh = async (token) =>
  httpService({
    url: "/auth/refresh",
    options: {
      method: "POST",
      data: {
        token,
      },
    },
  });

/**
 * @returns  {HttpResult<string>}
 */
export const logout = async () =>
  httpService({
    url: "/auth/logout",
    options: {
      method: "POST",
    },
  });

/**
 * @param {{ token: string; }} props
 * @returns  {HttpResult<{ token: string; }>}
 */
export const reissuePasswordChangeToken = async ({ token }) =>
  httpService({
    url: "/auth/reissue-password-change-token",
    options: {
      method: "POST",
      data: {
        token,
      },
    },
  });

/**
 * @param {{ email: string; }} props
 * @returns  {HttpResult<string>}
 */
export const sendResetPasswordLink = async ({ email }) =>
  httpService({
    url: "/auth/password-reset",
    options: {
      method: "POST",
      data: {
        email,
      },
    },
  });

/**
 * @param {{ oldPassword: string; newPassword: string; }} props
 * @returns  {HttpResult<string>}
 */
export const changePassword = async ({ oldPassword, newPassword }) =>
  httpService({
    url: "/auth/change_password",
    options: {
      method: "POST",
      data: {
        oldPassword,
        newPassword,
      },
    },
  });

/**
 * @param {{ token: string; newPassword: string; }} props
 * @returns  {HttpResult<Auth>}
 */
export const changePasswordByTokenAndLogin = async ({ token, newPassword }) =>
  httpService({
    url: "/auth/change-password-by-token",
    options: {
      method: "POST",
      data: {
        token,
        newPassword,
      },
    },
  });

/**
 * @param {{ password: string; }} props
 * @returns  {HttpResult<Auth>}
 */
export const createPasswordForPropertyManager = async ({ password }) =>
  httpService({
    url: "/auth/create-password",
    options: {
      method: "POST",
      data: {
        password,
      },
    },
  });

/**
 * @param {string} tenantId
 * @typedef {{code: string}} Props
 * @returns  {HttpResult<Props>}
 */
export const resendCodeByTenantId = async (tenantId) =>
  httpService({
    url: `/auth/resend-confirmation-code`,
    options: {
      method: "POST",
      data: {
        tenantId,
      },
    },
  });
