import {
  Box,
  Button,
  Stack,
  Typography,
  styled,
  InputLabel as MuiLabel,
} from "@mui/material";
import { customColors } from "models/customColors";
import { Link } from "react-router-dom";

export const DialogButton = styled(Button)`
  font-size: 1.2rem;
  min-height: 3rem;
  width: 100%;
`;

export const EmptySpace = styled(Box)`
  height: 1rem;
`;

export const Title = styled(Box)`
  font-weight: 600;
  color: #171717;
`;

export const HighlightedText = styled(`span`)`
  margin: 0 0.3rem;
  color: ${customColors.text.highlighted};
`;

export const InactiveText = styled(Box)`
  color: ${customColors.text.inactive};
`;

export const PlainText = styled("span")`
  color: ${customColors.text.plain};
`;

export const TitleSx = {
  height: 40,
  fontSize: {
    lg: 24,
    md: 22,
    sm: 17,
    xs: 17,
  },
};

export const BackButtonContent = styled(Stack)`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #000000;
`;

export const BackButtonContentSx = {
  fontSize: {
    lg: 23,
    md: 20,
    sm: 17,
    xs: 17,
  },
};

export const Text = styled(Box)`
  color: #284a70;
  font-weight: normal;
  opacity: 0.6;
  font-size: 1.1rem;
`;

export const TextSx = {
  fontSize: {
    lg: 20,
    md: 16,
    sm: 16,
    xs: 16,
  },
};

export const ControlButton = styled(Button)`
  padding: 5px 30px;
  font-size: 1.1rem;
  min-height: 2.8rem;
  width: 100%;
`;

export const AuthFormContainer = styled(Stack)`
  margin-left: 10%;
  margin-right: 10%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;

  color: ${({ theme }) => theme.palette.primary.main};

  @media screen and (orientation: portrait) and (max-device-width: 1024px) {
    margin: 0 1rem;
    width: calc(100% - 2rem);
  }
`;

export const AuthTitle = styled(Title)`
  width: 100%;
  font-size: 3rem;
  text-align: center;
`;

export const AuthTitleSx = {
  marginBottom: 1,
  minHeight: 40,
  fontSize: {
    lg: 32,
    md: 28,
    sm: 22,
    xs: 22,
  },
};

export const AuthLabel = styled(Box)`
  @media screen and (orientation: portrait) and (max-device-width: 1024px) {
    font-size: 0.8rem;
  }
`;

export const Terms = styled(Link)`
  text-decoration: unset;
  color: #5d60dd;

  @media screen and (orientation: portrait) and (max-device-width: 1024px) {
    font-size: 0.8rem;
  }
`;

export const AuthLink = styled(Link)`
  text-decoration: unset;
  color: #5d60dd;
`;

export const DialogMainButton = styled(Button)`
  font-size: 15px;
  height: 48px;
  padding: 12px 80px;
  border-radius: 12px;
  gap: 10px;

  @media screen and (orientation: portrait) and (max-device-width: 1024px) {
    padding: 12px;
    width: 100%;
  }
`;

export const MainButton = styled(DialogMainButton)`
  width: 400px;
  padding: 12px 24px 12px 24px;

  @media screen and (orientation: portrait) and (max-device-width: 1024px) {
    padding: 12px;
    width: 100%;
  }
`;

export const MainButtonLabel = styled(Typography)`
  font-size: 15px;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
`;

export const Header = styled(Box)`
  font-size: 32px;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 0.30000001192092896px;
  text-align: center;
`;

export const Message = styled(Box)`
  max-width: 433px;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
`;

export const CommonButton = styled(Button)`
  /* font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em; */
  /* text-align: left; */
`;

export const SimpleButton = styled(Button)`
  /* font-size: 1rem; */
  align-self: flex-start;
  /* font-weight: normal; */
`;

export const AddItemButton = styled(CommonButton)`
  /* font-size: 14px;
  text-align: left; */
  color: #007aff;
`;

export const EditItemButton = styled(Button)`
  margin: 0;
  padding: 4px 10px;
  /* font-size: 14px;
  font-weight: normal; */
`;

export const EditMobileItemButton = styled(Button)`
  margin: 0;
  padding: 5px 7px;
  font-size: 0.75rem;
  font-weight: normal;
`;

export const DeleteItemButton = styled(AddItemButton)`
  margin-top: 0;
  color: red;
`;

export const OutletHeader = styled(Stack)`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

  @media screen and (orientation: portrait) and (max-device-width: 1024px) {
    font-size: 0.8rem;
  }
`;

export const AddNewItemButton = styled(Button)`
  flex: 1 1 auto;

  border-radius: 12px;
  & p {
    font-weight: normal;
  }
`;

export const ContentPanel = styled(Box)`
  border: solid thin ${customColors.border};
  border-radius: 15px;

  background-color: white;
`;

export const Info = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  gap: 1em;
`;

export const InfoItem = styled(Stack)`
  position: relative;
  padding: 0.5em 1em;
  flex: 1 1 auto;
  justify-content: center;
  align-items: flex-start;
  gap: 0.3em;

  border: thin solid ${customColors.border};
  border-radius: 15px;

  background-color: white;
`;
export const HorizontalInfoItem = styled(Stack)`
  position: relative;
  padding: 0.5em 1em;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;

  border: thin solid ${customColors.border};
  border-radius: 15px;

  background-color: white;
`;

export const ItemTitle = styled(Box)`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: ${customColors.text.inactive};
`;

export const InputLabel = styled(MuiLabel)`
  margin-left: 0.3em;
`;

export const MoveOutDateTime = styled(MuiLabel)`
  margin-top: 0.4em;
  margin-left: 0.3em;
  color: ${customColors.text.highlighted};
`;

export const ItemValue = styled(Box)`
  font-size: 1.2em;
  font-weight: 400;

  @media (max-width: 1600px) {
    font-size: 1.1em;
  }

  @media (max-width: 1440px) {
    font-size: 1em;
  }

  @media (max-width: 1380px) {
    font-size: 0.9em;
  }

  @media (max-width: 1280px) {
    font-size: 0.8em;
  }

  @media (max-width: 1024px) {
    font-size: 0.7em;
  }
`;
