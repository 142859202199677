import { createTheme, responsiveFontSizes } from "@mui/material";
import { customColors } from "models/customColors";

export const theme = responsiveFontSizes(
  createTheme({
    shape: {
      borderRadius: 15,
    },
    typography: {
      fontFamily: ["Messina Sans", "sans-serif"].join(","),
    },
    palette: {
      primary: {
        main: "#171717",
        dark: "#000",
      },
      text: {
        primary: customColors.text.plain,
      },
      info: {
        main: customColors.info,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 12,
            textTransform: "none",
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            textAlign: "center",
            fontWeight: "bold",
            padding: "35px 80px 0 80px",
            fontSize: "1.5rem !important",
            color: customColors.title.plain,
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            textAlign: "center",
            fontSize: "1.1rem",
            padding: "0 80px",
            color: customColors.text.plain,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: "1rem",
            gap: "20px",
            maxHeight: "calc(100% - 20px)",
            maxWidth: "800px",
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            justifyContent: "center",
            alignItems: "center",
            padding: "0 80px 30px 80px",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            borderRadius: "12px !important",
          },
        },
      },

      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: "#284A70",
            borderRadius: "20px",
            padding: "8px 15px",
            border: "1px solid #284A70",
          },
          arrow: {
            color: "#284A70",
          },
        },
      },
    },
  })
);
