import { localStorageNameEnum } from "../models/constants";

export const statusMessages = {
  200: "OK",
  201: "Created",
  202: "Accepted",
  203: "Non-Authoritative Information",
  204: "No Content",
  205: "Reset Content",
  206: "Partial Content",
  207: "Multi-Status (WebDAV)",
  208: "Already Reported (WebDAV)",
  226: "IM Used",
  300: "Multiple Choices",
  301: "Moved Permanently",
  302: "Found",
  303: "See Other",
  304: "Not Modified",
  305: "Use Proxy",
  306: "(Unused)",
  307: "Temporary Redirect",
  308: "Permanent Redirect (experimental)",
  400: "Bad Request",
  401: "Unauthorized",
  402: "Payment Required",
  403: "Forbidden",
  404: "Not Found",
  405: "Method Not Allowed",
  406: "Not Acceptable",
  407: "Proxy Authentication Required",
  408: "Request Timeout",
  409: "Conflict",
  410: "Gone",
  411: "Length Required",
  412: "Precondition Failed",
  413: "Request Entity Too Large",
  414: "Request-URI Too Long",
  415: "Unsupported Media Type",
  416: "Requested Range Not Satisfiable",
  417: "Expectation Failed",
  418: "I'm a teapot (RFC 2324)",
  420: "Enhance Your Calm (Twitter)",
  422: "Unprocessable Entity (WebDAV)",
  423: "Locked (WebDAV)",
  424: "Failed Dependency (WebDAV)",
  425: "Reserved for WebDAV",
  426: "Upgrade Required",
  428: "Precondition Required",
  429: "Too Many Requests",
  431: "Request Header Fields Too Large",
  444: "No Response (Nginx)",
  449: "Retry With (Microsoft)",
  450: "Blocked by Windows Parental Controls (Microsoft)",
  451: "Unavailable For Legal Reasons",
  499: "Client Closed Request (Nginx)",
  500: "Internal Server Error",
  501: "Not Implemented",
  502: "Bad Gateway",
  503: "Service Unavailable",
  504: "Gateway Timeout",
  505: "HTTP Version Not Supported",
  506: "Variant Also Negotiates (Experimental)",
  507: "Insufficient Storage (WebDAV)",
  508: "Loop Detected (WebDAV)",
  509: "Bandwidth Limit Exceeded (Apache)",
  510: "Not Extended",
  511: "Network Authentication Required",
  598: "Network read timeout error",
  599: "Network connect timeout error",
};

const isDev = process.env.REACT_APP_USER_BRANCH === "dev";

/**
 * Returns only two variants of the result:
 *  1) In case of successful result:  { value: <returned value> }
 *  2) When something goes wrong: { error: <error message>, status: number }
 * @param {import("types/commonTypes").HttpServiceProps} props
 * @template T
 * @returns {Promise<import("types/commonTypes").HttpResult<T>>}
 */
export const httpService = async ({ url, options, token, errorCallback }) => {
  const storedToken = window.sessionStorage.getItem(
    localStorageNameEnum.AUTH_TOKEN
  );
  const { data } = options;

  const headers = {
    "Content-Type": "application/json",
  };

  if (token !== undefined) {
    headers.Authorization = `Bearer ${token}`;
  } else if (storedToken && url !== "/auth/refresh") {
    headers.Authorization = `Bearer ${storedToken}`;
  }

  try {
    const response = await fetch(
      `${
        isDev
          ? process.env.REACT_APP_API_DEV_URL
          : process.env.REACT_APP_API_URL
      }${url}`,
      {
        ...options,
        headers,
        body: JSON.stringify(data),
      }
    );

    if (response?.status === 401) {
      const error = new Error(statusMessages[401]);
      // @ts-ignore
      error.status = 401;
      throw error;
    }

    const contentType = response?.headers?.get("Content-Type");
    let json;

    if (contentType === "application/json") {
      try {
        json = await response.json();
      } catch (e) {
        throw new Error(`JSON Error in API response: ${e.message}`);
      }
    }

    if (response?.status !== 200) {
      const error = new Error(
        response?.status
          ? json?.message && json.message !== ""
            ? json.message
            : statusMessages[response?.status]
          : // `${response?.status} - ${
            //     json?.message ?? statusMessages[response?.status]
            //   }`
            "Network Error"
      );
      // @ts-ignore
      error.status = response?.status;
      throw error;
    }

    return {
      value: json,
    };
  } catch (error) {
    console.error(`httpService`, error);
    return {
      error: String(error.message).replace(/Error:/g, ""),
      // error: 'Network error'
      status: error.status,
    };
  }
};
