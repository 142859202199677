import React from "react";

import { Stack, Box, styled } from "@mui/material";
import * as SC from "SC";

const Container = styled(Stack)`
  padding: 0 4rem 4rem 4rem;

  @media screen and (orientation: portrait) and (max-device-width: 1024px) {
    padding: 0 1rem 1rem 1rem;
  }
`;

const Heading = styled(Box)`
  font-weight: 600;
`;

export const TermsPage = () => {
  return (
    <Container>
      <SC.AuthTitle sx={{ ...SC.AuthTitleSx }}>
        {"Terms of Service and Privacy Policy"}
      </SC.AuthTitle>
      <Heading>Introduction</Heading>
      <p>
        Welcome to DoorBill’s, LLC. We respect your privacy and are committed to
        protecting your personal data. This privacy policy will inform you about
        how we look after your personal data when you visit our website
        (regardless of where you visit from) and tell you about your privacy
        rights and how the law protects you.
      </p>
      <Heading>Who we are</Heading>
      <p>
        DoorBill’s, LLC is the controller and responsible for your personal
        data. Contact details:
        <ul>
          <li>Email: phil@doorbill.com</li>
          <li>Address: 15 W. Harris, Lagrange, Illinois</li>
          <li>Phone: 312.888.6473</li>
        </ul>
      </p>
      <Heading>Third-party links</Heading>
      <p>
        This website may include links to third-party websites, plugins, and
        applications. Clicking on those links may allow third parties to collect
        or share data about you. We do not control these third-party websites
        and are not responsible for their privacy statements.
      </p>
      <Heading>Data We Collect</Heading>
      <p>
        We may collect, use, store, and transfer different kinds of personal
        data about you, including:
        <ul>
          <li>Identity Data: names, usernames, etc.</li>
          <li>Contact Data: address, email address, phone numbers, etc.</li>
          <li>Technical Data: IP addresses, login data, etc.</li>
          <li>
            Usage Data: information on how you use our website, products, and
            services.
          </li>
        </ul>
      </p>
      <Heading>How We Use Your Data</Heading>
      <p>
        We will only use your personal data when the law allows us to. Most
        commonly, we will use your personal data:
        <ul>
          <li>To process and deliver your orders.</li>
          <li>To manage our relationship with you.</li>
          <li>To administer and protect our business and website.</li>
          <li>
            To deliver relevant website content and advertisements to you.
          </li>
        </ul>
      </p>
      <Heading>Data Security</Heading>
      <p>
        We have put in place appropriate security measures to prevent your
        personal data from being accidentally lost, used, or accessed in an
        unauthorized way. We also limit access to your personal data to those
        employees, agents, contractors, and other third parties who have a
        business need to know.
      </p>
      <Heading>Data Retention</Heading>
      <p>
        We will only retain your personal data for as long as necessary to
        fulfill the purposes for which we collected it, including for the
        purposes of satisfying any legal, accounting, or reporting requirements.
      </p>
      <Heading>Your Legal Rights </Heading>
      <p>
        You have the right to:
        <ul>
          <li>Request access to your personal data.</li>
          <li>
            Request correction of the personal data that we hold about you.
          </li>
          <li>Request erasure of your personal data.</li>
          <li>Object to processing of your personal data.</li>
        </ul>
      </p>
      <Heading>Changes to the Privacy Policy </Heading>
      <p>
        We may update this privacy policy from time to time. We will notify you
        of any changes by posting the new privacy policy on this page.
      </p>
    </Container>
  );
};
