import React, { useContext, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useNavigate, useLocation, Outlet, Link } from "react-router-dom";
import { GlobalContext } from "store/globalContext";
import { Box, Button, Stack, styled } from "@mui/material";
import * as SC from "./CommonLayout.style";
import { Confirmation } from "./Confirmation";
import { ErrorDialog } from "./ErrorDialog";
import { WarningDialog } from "./WarningDialog";
import { ModalSpinner } from "./ModalSpinner";
import { logoutAction } from "store/reducers/userReducer";
import MenuIcon from "assets/img/menu.svg";
import LogoImage from "assets/img/doorbill_logo.svg";
import * as FP from "utils/fp-js";
import * as Icons from "UI/Icons";
import { customColors } from "models/customColors";
import { capitalizeFirstLetter } from "utils/helpers";
import { useApiRequest } from "api/useApiRequest";
import { getStripeConnectLink } from "api/stripeConnectApi";
import { showSpinner } from "store/reducers/spinnerReducer";
import { MOBILE_MENU_CLOSE_DELAY_IN_MS } from "models/constants";
import { useIsMobilePortraitScreen } from "hooks/useIsMobilePortraitScreen";
import { NavMenuContent } from "./NavMenuContent";

const BreadCrumbsLink = styled(Link)`
  text-decoration: none;
  color: ${customColors.text.inactive};
`;
const BreadCrumbsClickLink = styled(Box)`
  color: ${customColors.text.inactive};
  cursor: pointer;
`;

export const CommonLayout = ({ menu }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { callApiRequest } = useApiRequest();
  const buildingIdForReturn = useAppSelector(
    (state) => state.buildingIdForReturn
  );
  const isMobilePortraitScreen = useIsMobilePortraitScreen();

  const currentMenuData = menu.find(({ path }) => path === pathname);

  const StartIcon = currentMenuData?.StartIcon ?? (() => null);

  const dispatch = useAppDispatch();
  const spinner = useAppSelector((state) => state.spinner);
  const email = useAppSelector((state) => state.user.email);
  const firstName = useAppSelector((state) => state.user.firstName);
  const lastName = useAppSelector((state) => state.user.lastName);
  const id = useAppSelector((state) => state.user.id);
  const role = useAppSelector((state) => state.user.role);
  const roleLabel = role
    .split("_")
    .map((value) => capitalizeFirstLetter(value))
    .join(" ");
  const fullName = `${firstName} ${lastName}`;
  const stripeAccountId = useAppSelector((state) => state.user.stripeAccountId);

  const { openConfirmationWithCallback } = useContext(GlobalContext);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const logout = () => {
    navigate(`/`);
    dispatch(logoutAction());
  };

  const handleLogoutWithCallback = () =>
    openConfirmationWithCallback({
      title: "Confirmation needed",
      message: "Do you really want to log out?",
      callbackFn: logout,
      buttonLabels: ["Cancel", "Logout"],
      closeIcon: false,
    });

  const handleRegisterStripe = () => {
    FP.asyncEither({
      value: callApiRequest({
        apiRequest: getStripeConnectLink,
        params: {
          landlordId: id,
        },
      }),
      rightFn: (value) => {
        const link = value?.link;
        if (link) {
          dispatch(showSpinner());
          window.location.href = link;
        } else {
          throw new Error("The wrong stripe connect link received");
        }
      },
    });
  };

  const handleReturnToBuilding = () => {
    if (buildingIdForReturn.property) {
      navigate(`/building/${buildingIdForReturn.property}`);
      return;
    }
    if (buildingIdForReturn.finance) {
      navigate(`/building_finance/${buildingIdForReturn.finance}`);
      return;
    }
    navigate(-1);
  };

  const isDev = process.env.REACT_APP_USER_BRANCH === "dev";

  return (
    <SC.Container>
      {isDev && (
        <Box
          sx={{
            fontSize: "0.8rem",
            color: "red",
            position: "fixed",
            left: "55px",
            top: "1px",
          }}
        >
          dev environment
        </Box>
      )}
      {isMobilePortraitScreen ? (
        isMobileMenuOpen && (
          <SC.Overlay
            onClick={() =>
              setTimeout(
                () => setIsMobileMenuOpen(false),
                MOBILE_MENU_CLOSE_DELAY_IN_MS
              )
            }
          >
            <SC.MobileNavigation>
              <NavMenuContent
                menu={menu}
                roleLabel={roleLabel}
                handleLogoutWithCallback={handleLogoutWithCallback}
                fullName={fullName}
                email={email}
                isMobile
              />
            </SC.MobileNavigation>
          </SC.Overlay>
        )
      ) : (
        <SC.Navigation>
          <NavMenuContent
            menu={menu}
            roleLabel={roleLabel}
            handleLogoutWithCallback={handleLogoutWithCallback}
            fullName={fullName}
            email={email}
          />
        </SC.Navigation>
      )}
      <SC.Content flex={1}>
        {isMobilePortraitScreen ? (
          <Stack>
            <SC.MobileHeader>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{ width: "100%" }}
              >
                <Stack
                  direction={"row"}
                  gap={1}
                  onClick={() => setIsMobileMenuOpen(true)}
                >
                  <img src={MenuIcon} width={24} alt="open menu" />
                  Menu
                </Stack>
                <Box>
                  <Link to="/">
                    <img src={LogoImage} width={117} alt="avatar" />
                  </Link>
                </Box>
              </Stack>
            </SC.MobileHeader>
            {!stripeAccountId && role === "LANDLORD" && (
              <Button
                color="info"
                size="small"
                onClick={handleRegisterStripe}
                sx={{ margin: 0 }}
              >
                Register your Stripe Account
              </Button>
            )}
            {FP.match(true)
              .on(
                pathname.startsWith("/building/"),
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  pt={1}
                  pb={1}
                >
                  <Stack direction={"row"} gap={1} sx={{ userSelect: "none" }}>
                    <Icons.HomeIcon color={customColors.text.inactive} />
                    <BreadCrumbsLink to="/">My buildings</BreadCrumbsLink>
                    <Box>/</Box>
                    Building details
                  </Stack>
                </Stack>
              )
              .on(
                pathname.startsWith("/building_finance/"),
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  pt={1}
                  pb={1}
                >
                  <Stack direction={"row"} gap={1} sx={{ userSelect: "none" }}>
                    <Icons.FinanceIcon color={customColors.text.inactive} />
                    <BreadCrumbsLink to="/payments">Payments</BreadCrumbsLink>
                    <Box>/</Box>
                    Building payments
                  </Stack>
                </Stack>
              )
              .on(
                pathname.startsWith("/building_debts/"),
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  pt={1}
                  pb={1}
                >
                  <Stack direction={"row"} gap={1} sx={{ userSelect: "none" }}>
                    <Icons.FinanceIcon color={customColors.text.inactive} />
                    <BreadCrumbsLink to="/debts">Debts</BreadCrumbsLink>
                    <Box>/</Box>
                    Building debts
                  </Stack>
                </Stack>
              )
              .on(
                pathname.startsWith("/building_pap_reports/"),
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  pt={1}
                  pb={1}
                >
                  <Stack direction={"row"} gap={1} sx={{ userSelect: "none" }}>
                    <Icons.FinanceIcon color={customColors.text.inactive} />
                    <BreadCrumbsLink to="/pap_reports">
                      PAP Reports
                    </BreadCrumbsLink>
                    <Box>/</Box>
                    Access Logs
                  </Stack>
                </Stack>
              )
              .on(
                pathname.startsWith("/assignment/"),
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  pt={1}
                  pb={1}
                >
                  <Stack direction={"row"} gap={1} sx={{ userSelect: "none" }}>
                    <Box onClick={() => navigate("/")}>
                      <Icons.HomeIcon color={customColors.text.inactive} />
                    </Box>
                    <Box>/</Box>
                    <BreadCrumbsClickLink onClick={handleReturnToBuilding}>
                      Building details
                    </BreadCrumbsClickLink>
                    <Box>/</Box>
                    Assignment details
                  </Stack>
                </Stack>
              )
              .otherwise(null)}
          </Stack>
        ) : (
          <>
            <SC.Header>
              <Stack>
                {FP.match(true)
                  .on(
                    pathname.startsWith("/building/"),
                    <Stack
                      direction={"row"}
                      gap={1}
                      sx={{ userSelect: "none" }}
                    >
                      <Icons.HomeIcon color={customColors.text.inactive} />
                      <BreadCrumbsLink to="/">My buildings</BreadCrumbsLink>
                      <Box>/</Box>
                      Building details
                    </Stack>
                  )
                  .on(
                    pathname.startsWith("/building_finance/"),
                    <Stack
                      direction={"row"}
                      gap={1}
                      sx={{ userSelect: "none" }}
                    >
                      <Icons.FinanceIcon color={customColors.text.inactive} />
                      <BreadCrumbsLink to="/payments">Payments</BreadCrumbsLink>
                      <Box>/</Box>
                      Building payments
                    </Stack>
                  )
                  .on(
                    pathname.startsWith("/building_debts/"),
                    <Stack
                      direction={"row"}
                      gap={1}
                      sx={{ userSelect: "none" }}
                    >
                      <Icons.FinanceIcon color={customColors.text.inactive} />
                      <BreadCrumbsLink to="/debts">Debts</BreadCrumbsLink>
                      <Box>/</Box>
                      Building debts
                    </Stack>
                  )
                  .on(
                    pathname.startsWith("/building_pap_reports/"),
                    <Stack
                      direction={"row"}
                      gap={1}
                      sx={{ userSelect: "none" }}
                    >
                      <Icons.FinanceIcon color={customColors.text.inactive} />
                      <BreadCrumbsLink to="/pap_reports">
                        PAP Reports
                      </BreadCrumbsLink>
                      <Box>/</Box>
                      Access Logs
                    </Stack>
                  )
                  .on(
                    pathname.startsWith("/assignment/"),
                    <Stack
                      direction={"row"}
                      gap={1}
                      sx={{ userSelect: "none" }}
                    >
                      <Icons.HomeIcon color={customColors.text.inactive} />
                      <BreadCrumbsLink to="/">My property</BreadCrumbsLink>
                      <Box>/</Box>
                      <BreadCrumbsClickLink onClick={handleReturnToBuilding}>
                        Building details
                      </BreadCrumbsClickLink>
                      <Box>/</Box>
                      Assignment details
                    </Stack>
                  )
                  .otherwise(
                    <Stack direction={"row"} gap={1}>
                      <StartIcon />
                      {currentMenuData?.label}
                    </Stack>
                  )}
              </Stack>
              <Link to="/">
                <img src={LogoImage} width={117} alt="avatar" />
              </Link>
            </SC.Header>
            {!stripeAccountId && role === "LANDLORD" && (
              <Button
                color="info"
                size="small"
                onClick={handleRegisterStripe}
                sx={{ margin: 0 }}
              >
                Register your Stripe Account
              </Button>
            )}
          </>
        )}
        <SC.OutletHolder>
          <Outlet />
        </SC.OutletHolder>
      </SC.Content>
      {/* <SC.Notifications>Notifications</SC.Notifications> */}
      <Confirmation />
      <ErrorDialog />
      <WarningDialog />
      <ModalSpinner open={spinner} />
    </SC.Container>
  );
};
