import { combineReducers, createAction, createReducer } from "@reduxjs/toolkit";
import user, { initialState as userInitialState } from "./userReducer";
import confirmation from "./confirmationReducer";
import error from "./errorReducer";
import spinner from "./spinnerReducer";
import isDataChanged from "./isDataChangedReducer";
import isRefreshPending from "./isRefreshPendingReducer";
import onboarding from "./onboardingReducer";
import buildingIdForReturn from "./buildingIdForReturnReducer";
import { localStorageNameEnum } from "models/constants";

/**
 * @template T
 * @typedef {import("types/stateTypes").PayloadPreparator<T>} PayloadPreparator
 */

const appReducer = combineReducers({
  confirmation,
  error,
  user,
  spinner,
  isDataChanged,
  isRefreshPending,
  onboarding,
  buildingIdForReturn,
});

export const exampleAction = createAction(
  "root/exampleAction",
  /**
   * @type {PayloadPreparator<string>}
   */
  (value) => ({ payload: value })
);

export const logoutAction = createAction("root/logout");

const rootReducer = createReducer(
  /** @type {ReturnType<typeof appReducer>} */ (undefined),
  (builder) => {
    builder
      .addCase(exampleAction, (state, { payload }) => {
        // Just an example
        // state.prop = payload
      })
      .addCase(logoutAction, (state) => {
        state.error.message = undefined;
        state.user = userInitialState;
        window.sessionStorage.removeItem(localStorageNameEnum.AUTH_TOKEN);
        window.localStorage.removeItem(localStorageNameEnum.AUTH_REFRESH_TOKEN);
      });

    builder.addDefaultCase(appReducer);
  }
);

export default rootReducer;
