import { useEffect, useRef } from "react";

/**
 *
 * Helps to avoid second rendering in dev mode (React Strict Mode)
 *
 * @param {{
 *  loadFn: () => void;
 *  unloadFn?: () => void;
 * }} props
 */
export const useUnrestrictedEffect = ({ loadFn, unloadFn = () => null }) => {
  const isLoaded = useRef(false);

  useEffect(() => {
    if (!isLoaded.current) {
      isLoaded.current = true;
      loadFn();
      return () => {};
    }
    return () => {
      unloadFn();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
