import React from "react";
import { CommonLayout } from "components/CommonLayout";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import * as UI from "UI";
import { TermsPage } from "pages/TermsPage";
import { AdminPropertyPage } from "pages/AdminPropertyPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <CommonLayout
        menu={[
          { label: "My buildings", path: "/", StartIcon: UI.HomeIcon },
        ]}
      />
    ),
    children: [
      {
        path: "/",
        element: <AdminPropertyPage />,
      },
      {
        path: "/password_change/:token",
        element: <Navigate to={"/"} />,
      },
      {
        path: "/forgot",
        element: <Navigate to={"/"} />,
      },
      {
        path: "/terms",
        element: <TermsPage />,
      },
      {
        path: "*",
        element: <Navigate to={"/"} />,
      },
    ],
  },
]);

const SuperAdmin = () => <RouterProvider router={router} />;

export default SuperAdmin;