import React, { useContext } from "react";
import { Box, Stack, Typography, styled } from "@mui/material";
import { GlobalContext } from "store/globalContext";
import { Link, useLocation } from "react-router-dom";
import { customColors } from "models/customColors";
import * as UI from "UI";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { resetIsDataChanged } from "store/reducers/isDataChangedReducer";

export const Container = styled(Stack)`
  &:hover,
  &:focus {
    background-color: ${customColors.menu.hover.background};
  }
`;

export const menuLinkSx = {
  minHeight: "45px",
  borderRadius: "8px",
  paddingLeft: "0",
  paddingRight: "10px",
};

const MenuItem = styled(Link)`
  width: 100%;
  text-decoration: none;
`;

const ClickItem = styled(Box)`
  width: 100%;
  cursor: pointer;
`;

const IconHolder = styled(Stack)`
  width: 36px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const MenuLed = styled(Box)`
  margin-right: 10px;
  width: 4px;
  height: 16px;
  top: 15px;
  border-radius: 3px;
  background: ${({
    // @ts-ignore
    isActive,
  }) => (isActive ? customColors.menu.selected.text : "none")};
`;

const Content = ({
  label,
  color,
  backgroundColor,
  ledColor,
  arrowColor,
  StartIcon,
}) => (
  <Container
    direction={"row"}
    alignItems={"center"}
    sx={{ ...menuLinkSx, color, backgroundColor }}
  >
    <MenuLed sx={{ backgroundColor: ledColor }} />
    <UI.MenuArrowIcon color={arrowColor} />
    <IconHolder>
      <StartIcon color={color} />
    </IconHolder>
    <Typography noWrap sx={{ fontSize: "14px" }}>
      {label}
    </Typography>
  </Container>
);

export const MenuLink = ({ label, to, StartIcon, handleClick = undefined }) => {
  const dispatch = useAppDispatch();
  const { openConfirmationWithCallback } = useContext(GlobalContext);
  const isDataChanged = useAppSelector((state) => state.isDataChanged);
  const { pathname } = useLocation();
  const isActive = pathname === to;

  const color = isActive
    ? customColors.menu.selected.text
    : customColors.menu.notSelected.text;

  const backgroundColor = isActive
    ? customColors.menu.selected.background
    : customColors.menu.notSelected.background;

  const ledColor = isActive ? customColors.menu.selected.text : "none";

  const arrowColor = isActive ? "none" : "black";

  const handleClickWithCheck = (e) => {
    if (isDataChanged) {
      e.preventDefault();
      openConfirmationWithCallback({
        title: "Confirmation needed",
        message: "All changes will be lost. Proceed?",
        callbackFn: () => {
          dispatch(resetIsDataChanged());

          if (handleClick) {
            handleClick(e);
            return;
          }

          setTimeout(() => e?.target?.click());
        },
        closeIcon: false,
      });
      return;
    }

    if (handleClick) {
      handleClick(e);
    }
  };

  return handleClick ? (
    <ClickItem onClick={handleClickWithCheck}>
      <Content
        label={label}
        color={color}
        backgroundColor={backgroundColor}
        StartIcon={StartIcon}
        arrowColor={arrowColor}
        ledColor={ledColor}
      />
    </ClickItem>
  ) : (
    <MenuItem to={to} onClick={handleClickWithCheck}>
      <Content
        label={label}
        color={color}
        backgroundColor={backgroundColor}
        StartIcon={StartIcon}
        arrowColor={arrowColor}
        ledColor={ledColor}
      />
    </MenuItem>
  );
};
