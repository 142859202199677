import { createAction, createReducer } from "@reduxjs/toolkit";

/**
 * @template T
 * @typedef {import('types/stateTypes').PayloadPreparator<T>} PayloadPreparator
 */

/** @type {boolean} */
const initialState = false;

export const setIsRefreshPending = createAction("isRefreshPending/set");
export const resetIsRefreshPending = createAction("isRefreshPending/unset");

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(setIsRefreshPending, () => true);
  builder.addCase(resetIsRefreshPending, () => false);
});

export default reducer;
