import { localStorageNameEnum } from "models/constants";
import { useAppDispatch } from "store/hooks";
import { showError, hideError } from "store/reducers/errorReducer";
import { showSpinner, hideSpinner } from "store/reducers/spinnerReducer";
import { logoutAction, restoreAuth } from "store/reducers/userReducer";

/**
 *
 * @template T
 * @typedef {import('types/commonTypes').HttpResult<T>} HttpResult
 */

export const useApiRequest = () => {
  const dispatch = useAppDispatch();

  /**
   * @template T
   * @param {{
   *   apiRequest: (params:any) => HttpResult<T>;
   *   params?: any;
   *   skipErrorHandling?: boolean;
   *   onError?: (error: string, status?: number) => void;
   *   onSuccess?: (value: T) => void;
   * }} props
   * @returns { HttpResult<T> }
   */
  const callApiRequest = async ({
    apiRequest,
    params,
    skipErrorHandling = false,
    onError,
    onSuccess,
  }) => {
    if (!skipErrorHandling) {
      dispatch(hideError());
      dispatch(showSpinner());
    }

    const result = await apiRequest(params);

    const error = result?.error;
    const status = result?.status;

    if (status === 401) {
      const refreshToken = window.localStorage.getItem(
        localStorageNameEnum.AUTH_REFRESH_TOKEN
      );
      if (refreshToken) {
        dispatch(restoreAuth(refreshToken));
      } else {
        dispatch(logoutAction());
      }
    }

    if (!skipErrorHandling) {
      dispatch(hideSpinner());
      if (error) {
        dispatch(showError(error));
      }
    }

    if (onError && error) {
      onError(error, status);
    }

    if (onSuccess && !error) {
      onSuccess(result.value);
    }

    return result;
  };
  return {
    callApiRequest,
  };
};
