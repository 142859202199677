import React from "react";
import { Box, Stack } from "@mui/material";
import * as SC from "./CommonLayout.style";
import { MenuLink } from "components/MenuLink";
import CloseMenuIcon from "assets/img/close.svg";
import AvatarImage from "assets/img/avatar.svg";
import * as UI from "UI";
import { VideoTips } from "./VideoTips";
import { useAppSelector } from "store/hooks";

export const NavMenuContent = ({
  fullName,
  roleLabel,
  email,
  menu,
  handleLogoutWithCallback,
  isMobile = false,
}) => {
  const role = useAppSelector((state) => state.user.role);

  return (
    <>
      <Box>
        <Stack gap={4} alignItems={"stretch"}>
          {isMobile && (
            <Stack direction={"row"} ml={0.5} gap={1}>
              <img src={CloseMenuIcon} width={22} alt="close menu" />
              Close Menu
            </Stack>
          )}
          <Stack
            direction={"row"}
            gap={1}
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
            marginBottom={2}
          >
            <img src={AvatarImage} alt="avatar" />
            <Stack direction={"column"}>
              {fullName && <SC.UserName>{fullName}</SC.UserName>}
              <SC.Role>{roleLabel}</SC.Role>
              <SC.Role>{email}</SC.Role>
            </Stack>
          </Stack>
        </Stack>
        <SC.Menu>
          {menu?.map(({ label, path, StartIcon }) => (
            <MenuLink
              key={path}
              label={label}
              to={path}
              StartIcon={StartIcon}
            />
          ))}
        </SC.Menu>
      </Box>
      <Stack>
        {!isMobile && role !== "SUPER_ADMIN" && <VideoTips />}
        <MenuLink
          label={"Log out"}
          to={""}
          StartIcon={UI.LogoutIcon}
          handleClick={handleLogoutWithCallback}
        />
      </Stack>
    </>
  );
};
