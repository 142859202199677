import { httpService } from "./httpService";

/**
 *
 * @template T
 * @typedef {import('types/commonTypes').HttpResult<T>} HttpResult
 */

/**
 * @param {{ landlordId: string; }} props
 * @returns  {HttpResult<{ link: string; }>}
 */
export const getStripeConnectLink = async ({ landlordId }) =>
  httpService({
    url: `/stripe/account-link/?landlordId=${landlordId}`,
    options: {
      method: "GET",
    },
  });
